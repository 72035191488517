import styled from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';
import { colors, fonts, fontSizes } from '../../../styles/variables';

export const Container = styled.div`
  background: ${colors.darkBlue};
  font-family: ${fonts.poppins};
  height: 100vh;
  left:0;
  position: fixed;
  top:0;
  width: 300px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 25px;
`;

export const Box = styled.div``;

export const Nav = styled.nav`
  margin-top: 30px;
`;

export const List = styled.ul`
  align-items: flex-start;
  flex-direction: column;
  font: ${fonts.roboto};
  font-size: ${fontSizes.md};
  width: 100%;
`;

export const ButtonLink = styled(ReactRouterLink)`
  align-items: center;
  color: inherit;
  display: flex;
  padding: ${({padding})=> padding && "10px 0"};
  transition: inherit;
  width: 100%;

  &:hover {
    color: ${colors.blue};
  }
`;

export const Button = styled.button`
  align-items: center;
  background-color: transparent;
  color: inherit;
  display: flex;
  font-family: inherit;
  font-size: inherit;
  transition: inherit;
  width: 100%;

  &:hover {
    color: ${colors.blue};
  }
`;

export const IconContainer = styled.div`
  border-radius: 12px;
  color: inherit;
  display: flex;
  font-size: 1.5rem;
  margin-right: 10px;
  padding: 13px;
`;

export const ChevronIconContainer = styled.div`
  color: inherit;
  display: flex;
  font-size: 1.5rem;
  margin-left: auto;
  transition: transform 250ms;
`;

export const Item = styled.li`
  align-items: center;
  color: ${({ active, preventActiveStyle }) =>
    active && !preventActiveStyle ? colors.blue : 'white'};
  display: flex;
  flex-direction: column;
  transition: color 100ms;
  width: 100%;

  & ${ButtonLink} ${IconContainer} {
    background: ${({ active, preventActiveStyle }) =>
      active && !preventActiveStyle ? colors.whiteLight : 'transparent'};
  }

  & ${Button} ${ChevronIconContainer} {
    ${({ active }) => active && 'transform: rotate(-180deg);'};
  }

  & ${List} {
    display: ${({ active }) => (active ? 'flex' : 'none')};
  }
`;

export const EmptyDiv = styled.div`
  flex-shrink: 0;
  height: 30px;
  margin-right: 10px;
  width: 50px;
`;

export const Badge = styled.div`
  align-items: center;
  background: ${colors.red};
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 25px;
  justify-content: center;
  margin-left: auto;
  width: 25px;
`;

export const BadgeCount = styled.p`
  color: white;
  font-size: 0.8rem;
`;

export const TopWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const BottomWrapper = styled.div`
  margin-top: auto;
`;

export const TitleWrapper = styled.div`
  color: white;
`;

export const Title = styled.h1`
  color: inherit;
  font-size: ${fontSizes.lg};
  font-weight: 500;
  line-height: 1;
`;

export const Text = styled.p`
  font-size: ${fontSizes.md};
  margin-top: 0.2em;
  /*  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
`;

export const ProfileDecor = styled.div`
  background-color: ${colors.blue};
  border-radius: 12px;
  flex-shrink: 0;
  height: 50px;
  margin-right: 10px;
  width: 50px;
`;
