import {
  CircleCheckIcon,
  CircleXIcon,
  ShoppingBagIcon,
  SpinnerIcon,
} from '../components/icons';

const durumaGoreIconGetir = (id) => {
  switch (id) {
    case 3:
      return <SpinnerIcon />;
    case 7:
    case 10:
    case 11:
      return <CircleXIcon />;
    case 6:
      return <CircleCheckIcon />;
    case 'siparisAlindi':
      return <ShoppingBagIcon />;
    default:
      break;
  }
};

export default durumaGoreIconGetir;
