import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import TimeAgo from 'react-timeago';
import { Table } from '../components';
import * as ROUTES from '../constants/routes';
import { OrderSituationIconContainer } from '../components/table/styles/table';
import { durumaGoreIconGetir, siparisTuruneGoreIconGetir } from '../helpers';
import { formatter } from '../constants/formatter';
import { SIPARIS_TUR } from '../constants/siparis-tur';

function TumSiparislerTableContainer({ siparisler }) {
  const handleSiparisClick = (e, id) => {
    e.stopPropagation();
  };
  return (
    <>
      {siparisler.map(
        ({
          siparis_id,
          siparis_tarihi,
          musteri_ad_soyad,
          cep_telefonu,
          bayi_adi,
          arac_plaka,
          toplam_tutar,
          urun_sayisi,
          teslimat_tur_id,
          teslimat_turu,
          siparis_tur_id,
          teslimat_tarihi,
          siparis_durum_adi,
          siparis_durum_id,
          siparis_tarihi_format,
          teslimat_adresi,
        }) => (
          <Table.Row key={uuidv4()}>
            <Table.RowLink
              onClick={(e) => handleSiparisClick(e, siparis_id)}
              to={`${ROUTES.SIPARIS_DETAY}/${siparis_id}`}
            >
              <Table.Data verticalAlign='top' width='5%'>
                <Table.IconContainer
                  variant={
                    siparis_tur_id === SIPARIS_TUR.CALL_CENTER ? 'purple' : ''
                  }
                >
                  {siparisTuruneGoreIconGetir(siparis_tur_id)}
                </Table.IconContainer>
              </Table.Data>
              <Table.Data width='15%'>
                <Table.Text>{musteri_ad_soyad}</Table.Text>
                <Table.TextSmall>{cep_telefonu}</Table.TextSmall>
              </Table.Data>
              <Table.Data width='15%'>
                <Table.Text>{teslimat_adresi[0]}</Table.Text>
                <Table.TextSmall>
                  {`${teslimat_adresi[1]} / ${teslimat_adresi[2]}`}
                </Table.TextSmall>
              </Table.Data>
              <Table.Data width='12%'>
                <Table.Text>{bayi_adi}</Table.Text>
                <Table.TextSmall>{arac_plaka}</Table.TextSmall>
              </Table.Data>
              <Table.Data width='10%'>
                <Table.Price>{toplam_tutar}</Table.Price>
                <Table.TextSmall>{urun_sayisi} Adet</Table.TextSmall>
              </Table.Data>
              <Table.Data verticalAlign='baseline' width='11%'>
                <Table.Text>
                  <TimeAgo
                    date={siparis_tarihi}
                    formatter={formatter}
                    title={siparis_tarihi_format}
                  />
                </Table.Text>
              </Table.Data>
              <Table.Data width='15%'>
                <Table.Order kind={teslimat_tur_id}>
                  {teslimat_turu}
                </Table.Order>
                <Table.Text>{teslimat_tarihi}</Table.Text>
              </Table.Data>

              <Table.Data verticalAlign='middle' width='17%'>
                <Table.OrderSituation durum={siparis_durum_id}>
                  <OrderSituationIconContainer>
                    {durumaGoreIconGetir(siparis_durum_id)}
                  </OrderSituationIconContainer>
                  {siparis_durum_adi}
                </Table.OrderSituation>
              </Table.Data>
            </Table.RowLink>
          </Table.Row>
        )
      )}
    </>
  );
}

export default TumSiparislerTableContainer;
