import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { POST } from '../../constants/endpoints';
import * as ROUTES from '../../constants/routes';
import { trimAndFormatNumber } from '../../helpers';
import usePostData from '../usePostData';

const defaultWarn = {
  isim: '',
  telefon: '',
};

function useMusteriEklemeModal1Logic() {
  const history = useHistory();
  const { telefon: telParam } = useParams();
  const [warn, setWarn] = useState(defaultWarn);
  const [isim, setIsım] = useState('');
  const [telefon, setTelefon] = useState(telParam || '');
  const { resource, postData } = usePostData();
  const { data } = resource?.resp || {};
  const { success, musteri_id } = data || {};

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputlariKontrolEt()) {
      postData(POST.MUSTERI_EKLEME, { isim, telefon });
    }
  };

  const handleTelefonChange = (e) => {
    const { value } = e.target;
    const formattedValue = trimAndFormatNumber(value);
    if (
      (/\d/.test(formattedValue) || !formattedValue) &&
      formattedValue.length <= 10
    ) {
      setTelefon(formattedValue);
    }
  };

  const handleIsımChange = (e) => {
    const isim = e.target.value;
    setIsım(isim);
  };

  const resetWarn = () => setWarn(defaultWarn);

  const inputlariKontrolEt = () => {
    resetWarn();
    const warnObj = { ...defaultWarn };
    if (!isim) {
      warnObj.isim = 'Lütfen isim giriniz';
    }
    if (!telefon) {
      warnObj.telefon = 'Lütfen telefon numarası giriniz';
    }

    if (warnObj.isim || warnObj.telefon) {
      setWarn(warnObj);
      return false;
    }
    return true;
  };

  const responseErrorKontrolEt = useCallback(() => {
    const warnObj = { ...defaultWarn };
    const { response } = resource?.error || {};
    const { isim, telefon } = response?.data || {};
    if (isim) {
      warnObj.isim = isim;
    }
    if (telefon) {
      warnObj.telefon = telefon;
    }
    if (warnObj.isim || warnObj.telefon) setWarn(warnObj);
  }, [resource]);

  const responseBodyKontrolEt = useCallback(() => {
    if (success && musteri_id) {
      history.push(`${ROUTES.MUSTERI_PROFIL}/${musteri_id}`);
    }
  }, [musteri_id, success, history]);

  useEffect(() => {
    responseBodyKontrolEt();
    responseErrorKontrolEt();
  }, [resource, responseBodyKontrolEt, responseErrorKontrolEt]);

  return {
    handleIsımChange,
    handleTelefonChange,
    handleSubmit,
    telefon,
    isim,
    warn,
  };
}

export default useMusteriEklemeModal1Logic;
