import React from 'react';
import {
  Container,
  Box,
  Title,
  Text,
  Button,
  IconContainer,
} from './styles/dialog';

export default function Dialog({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Dialog.Box = function DialogBox({ children, ...restProps }) {
  return <Box {...restProps}>{children}</Box>;
};

Dialog.Title = function DialogTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Dialog.Text = function DialogText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

Dialog.IconContainer = function DialogIconContainer({
  children,
  ...restProps
}) {
  return <IconContainer {...restProps}>{children}</IconContainer>;
};

Dialog.Button = React.forwardRef(({ children, ...restProps }, ref) => (
  <Button ref={ref} {...restProps}>
    {children}
  </Button>
));
