import React from 'react';
import { Backdrop, Form, Modal } from '../../../components';
import { useMusteriEklemeModal1Logic } from '../../../hooks';

function MusteriEklemeModal1({ callback }) {
  const {
    handleIsımChange,
    handleTelefonChange,
    handleSubmit,
    isim,
    telefon,
    warn,
  } = useMusteriEklemeModal1Logic();

  const handleVazgecClick = (e) => {
    callback();
    e.preventDefault()
  };

  

  return (
    <>
      <Backdrop handler={callback} />
      <Modal>
        <Modal.Wrapper>
          <Modal.Form onSubmit={handleSubmit}>
            <Modal.FieldContainer>
              <Modal.SectionTitle>Müşteri Ekle</Modal.SectionTitle>
              <Modal.Text>
                Yeni müşteri eklemek için aşağıdaki bilgileri doldurunuz.
              </Modal.Text>
            </Modal.FieldContainer>
            <Modal.FieldContainer>
              <Modal.SectionTitle>Kişisel Bilgiler</Modal.SectionTitle>
              <Modal.Fieldset>
                <div>
                  <Form.Label htmlFor='input-1'>Ad Soyad</Form.Label>
                  <Form.Input
                    id='input-1'
                    type='text'
                    placeholder='Lütfen Ad Soyad Giriniz'
                    value={isim}
                    onChange={handleIsımChange}
                    autoFocus
                  />
                  {warn.isim && <Form.Warn>{warn.isim}</Form.Warn>}
                </div>
                <div>
                  <Form.Label htmlFor='input-2'>Telefon Numarası</Form.Label>
                  <Form.Input
                    id='input-2'
                    type='text'
                    placeholder='0 (000) 000 00 00'
                    value={telefon}
                    onChange={handleTelefonChange}
                  />
                  {warn.telefon && <Form.Warn>{warn.telefon}</Form.Warn>}
                </div>
              </Modal.Fieldset>
            </Modal.FieldContainer>
            <Modal.Box>
              <Modal.Button onClick={handleVazgecClick}>Vazgeç</Modal.Button>
              <Modal.ButtonBlue>Kaydet</Modal.ButtonBlue>
            </Modal.Box>
          </Modal.Form>
        </Modal.Wrapper>
      </Modal>
    </>
  );
}

export default MusteriEklemeModal1;
