import styled from 'styled-components/macro';
import { commonFontSet } from '../../../styles/mixins';
import { colors, margin, radius } from '../../../styles/variables';

export const Container = styled.div`
  margin-top: ${({ noMargin }) => (noMargin ? '0' : '20px')};
  width: 100%;
`;

export const ImgContainer = styled.div`
  background-color: ${colors.gray};
  border-radius: ${radius.roundedSM};
  flex-shrink: 0;
  margin-right: ${margin.common};
  padding: 13px;
  width: 120px;
`;

export const Img = styled.img``;

export const Box = styled.div`
  display: flex;
`;

export const Text = styled.p`
  ${commonFontSet}
  color: ${({ color }) => color || colors.gray3};
`;

export const Price = styled.p`
  color: ${({ old }) => (old ? colors.grayText : colors.blue)};
  ${({ old }) => old && 'text-decoration:line-through; margin-right: 8px;'}
`;

export const CounterContainer = styled.div`
  align-items: center;
  background-color: ${colors.gray};
  border-radius: ${radius.roundedSM};
  color: ${colors.green};
  display: flex;
  margin-right: 15px;
`;

export const Button = styled.button`
  align-items: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  border-radius: ${radius.roundedSM};
  color: ${({ color }) => color || 'inherit'};
  font-size: 1.5rem;
  display: flex;
  height: 50px;
  justify-content: center;
  transition: background-color 100ms;
  width: 50px;

  &::hover,
  &:focus {
    background-color: gray;
  }
`;

export const TextContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ noMargin }) => (noMargin ? '0' : '16px')};
  width: 100%;
`;

export const CounterText = styled.p``;
