import { IncomingCallIcon, SmartphoneIcon } from '../components/icons';

export default function siparisTuruneGoreIconGetir(id) {
  switch (id) {
    case 3:
      return <SmartphoneIcon />;
    case 6:
      return <IncomingCallIcon />;
    default:
      break;
  }
}
