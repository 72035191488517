import React from 'react';
import {
  Container,
  Wrapper,
  LinkContainer,
  IconContainer,
  PageLink,
  Button,
  Text
} from './styles/pagination';

function Pagination({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

Pagination.Wrapper = function PaginationWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Pagination.PageLink = function PaginationPageLink({ children, ...rest }) {
  return <PageLink {...rest}>{children}</PageLink>;
};

Pagination.LinkContainer = function PaginationLinkContainer({
  children,
  ...rest
}) {
  return <LinkContainer {...rest}>{children}</LinkContainer>;
};

Pagination.IconContainer = function PaginationIconContainer({
  children,
  ...rest
}) {
  return <IconContainer {...rest}>{children}</IconContainer>;
};

Pagination.Button = function PaginationButton({
  children,
  ...rest
}) {
  return <Button {...rest}>{children}</Button>;
};

Pagination.Text = function PaginationText({
  children,
  ...rest
}) {
  return <Text {...rest}>{children}</Text>;
};

export default Pagination;
