import React from 'react';
import 'styled-components/macro';
import {
  Content,
  Dropdown,
  Flex,
  Form,
  Info,
  Section,
  Table,
  Dialog,
} from '../components';
import { ChevronIcon, CircleXIcon, EditIcon } from '../components/icons';
import { useDropdownLogic, useMusteriProfilLogic } from '../hooks';
import { v4 as uuidv4 } from 'uuid';
import {
  AdresBilgiModal,
  DialogContainer,
  MusteriBilgiModal,
  MusteriEklemeModalContainer2,
  MusteriProfilTableContainer,
  SiparisOlusturmaModalContainer1,
  SiparisOlusturmaModalContainer2,
  SiparisOlusturmaModalContainer3,
  SiparisOlusturmaModalContainer4,
} from '../containers';
import { Button } from '../components/';
import { ButtonDashed } from '../components/button';
import SiparisYokContainer from '../containers/siparis-yok';
function MusteriProfil() {
  const { showDropdown, handleToggleClick, aktifItem, closeDropdown } =
    useDropdownLogic('Müşteri İşlemleri');

  const {
    handlers,
    modal,
    setModal,
    siparis,
    setSiparis,
    musteriModalCallback,
    musteri,
    adresler,
    siparisler,
    defaultModalState,
    defaultSiparisState,
    getData,
    showDialog,
    adres
  } = useMusteriProfilLogic({ closeDropdown });
  const {
    handleSiparisOlusturClick,
    handleAdresOlusturClick,
    handleDialogOnayClick,
    handleDialogVazgecClick,
    handleMusteriBilgileriYonetClick,
    handleAdresBilgileriYonetClick,
  } = handlers;

  const getUrun = (state) => {
    const urunler = state
      .filter((urun) => urun.adet)
      .map(({ urun_id, adet, depozito }) => ({
        urun_id,
        adet,
        depozito: depozito === 'true',
      }));
    setSiparis({ ...siparis, urunler });
  };
  
  return (
    <>
      <Content>
        <Content.Wrapper>
          <Flex justify='space-between' align='flex-start'>
            <Flex
              direction='column'
              align='flex-start'
              width='auto'
              css='margin-right:1em; flex-shrink:10'
            >
              <Content.Title>Müşteri Profil</Content.Title>
              <Content.Text>
                Tüm detaylara ulaşabilir, düzenleme yapabilirsiniz.
              </Content.Text>
              {showDialog && (
                <DialogContainer
                  title='Bu numaraya ait müşteri bulunamadı'
                  text='Yeni müşteri eklemek ister misiniz?'
                  icon={<CircleXIcon />}
                  variant='red'
                  handleOnayClick={handleDialogOnayClick}
                  noBtn={
                    <Dialog.Button
                      onClick={handleDialogVazgecClick}
                      variant='gray'
                      autoFocus
                    >
                      Vazgeç
                    </Dialog.Button>
                  }
                />
              )}
            </Flex>
            <Form.Box>
              <Dropdown.Wrapper width='270px'>
                <Dropdown.ToggleButton onClick={handleToggleClick}>
                  <Dropdown.Text>{aktifItem}</Dropdown.Text>
                  <ChevronIcon />
                </Dropdown.ToggleButton>
                {showDropdown && (
                  <Dropdown>
                    <Dropdown.List>
                      <Dropdown.Item>
                        <Dropdown.Button
                          onClick={handleMusteriBilgileriYonetClick}
                        >
                          Müşteri Bilgilerini Yönet
                        </Dropdown.Button>
                      </Dropdown.Item>
                    </Dropdown.List>
                  </Dropdown>
                )}
              </Dropdown.Wrapper>
              {adresler?.length ? (
                <Button onClick={handleSiparisOlusturClick} noMargin>
                  Sipariş Oluştur
                </Button>
              ) : null}
            </Form.Box>
          </Flex>
        </Content.Wrapper>
        <Content.Wrapper>
          <Flex align='flex-start' css='padding-bottom:2em;'>
            <Flex
              direction='column'
              align='flex-start'
              flexBasis='40%'
              css='margin-right:30px;'
            >
              <Section noMargin>
                <Section.Wrapper>
                  <Flex>
                    <Flex justify='space-between'>
                      <Section.Title>Kullanıcı Bilgileri</Section.Title>
                      {musteri?.yeni_musteri && (
                        <Table.Pill>Yeni Müşteri</Table.Pill>
                      )}
                    </Flex>
                  </Flex>
                  <Section.Inner>
                    <Info>
                      <Info.Row>
                        <Info.TitleText>Ad Soyad</Info.TitleText>
                        <Info.Text>{musteri?.musteri_ad_soyad}</Info.Text>
                      </Info.Row>
                      <Info.Row>
                        <Info.TitleText>Telefon Numarası</Info.TitleText>
                        <Info.Text>{musteri?.cep_telefonu}</Info.Text>
                      </Info.Row>
                      <Info.Row>
                        <Info.TitleText>Kayıt Tarihi</Info.TitleText>
                        <Info.Text>{musteri?.kayit_tarihi}</Info.Text>
                      </Info.Row>
                    </Info>
                  </Section.Inner>
                </Section.Wrapper>
              </Section>
              <Section>
                <Section.Wrapper>
                  <Flex>
                    <Section.Title>Adres Bilgileri</Section.Title>
                  </Flex>
                  <Section.Inner css='max-height:40vh; overflow:auto;'>
                    {adresler?.length ? (
                      <Info>
                        {adresler.map(
                          ({
                            adres_id,
                            adres_adi,
                            teslimat_adresi,
                            varsayilan,
                          }) => (
                            <Info.Card
                              variant={varsayilan ? 'green' : ''}
                              key={uuidv4()}
                            >
                              <Info.CardTitle>
                                Adres ({adres_adi})
                              </Info.CardTitle>
                              <Info.Text variant='gray'>
                                {teslimat_adresi[0]}
                              </Info.Text>
                              <Info.Text>{teslimat_adresi[1]}</Info.Text>
                              <Info.Button
                                onClick={() =>
                                  handleAdresBilgileriYonetClick(
                                    adres_id,
                                    teslimat_adresi[0]
                                  )
                                }
                                css='position: absolute; top:10px; right:10px;'
                              >
                                <EditIcon />
                              </Info.Button>
                            </Info.Card>
                          )
                        )}
                      </Info>
                    ) : null}

                    <ButtonDashed
                      onClick={handleAdresOlusturClick}
                      css={`
                        ${adresler?.length && 'margin-top:20px;'}
                      `}
                    >
                      Adres Oluştur
                    </ButtonDashed>
                  </Section.Inner>
                </Section.Wrapper>
              </Section>
            </Flex>
            <Flex direction='column' align='flex-start' flexBasis='60%'>
              <Section noMargin>
                <Section.Wrapper>
                  <Flex>
                    <Section.Title>Son Siparişler</Section.Title>
                    {musteri?.yeni_musteri && (
                      <Table.Pill css='visibility:hidden;'>
                        Yeni Müşteri
                      </Table.Pill>
                    )}
                    {/* Buradaki Table.Pill Height'ın kullanıcı bilgileri section'ı ile eşitlenmesi için eklendi. */}
                  </Flex>
                  {siparisler?.length ? (
                    <Table.Wrapper maxHeight='70vh'>
                      <Table>
                        <Table.Head>
                          <Table.Row>
                            <Table.DataHead width='10%'></Table.DataHead>
                            <Table.DataHead width='21%'>
                              Teslimat Adresi
                            </Table.DataHead>
                            <Table.DataHead width='19%'>
                              Tutar / Adet
                            </Table.DataHead>
                            <Table.DataHead width='20%'>
                              Sipariş Tarihi
                            </Table.DataHead>
                            <Table.DataHead width='30%'></Table.DataHead>
                          </Table.Row>
                        </Table.Head>
                        <Table.Body maxHeight='61vh;'>
                          <MusteriProfilTableContainer
                            siparisler={siparisler}
                          />
                        </Table.Body>
                      </Table>
                    </Table.Wrapper>
                  ) : (
                    <Section.Inner css='height:70vh;'>
                      <SiparisYokContainer />
                    </Section.Inner>
                  )}
                </Section.Wrapper>
              </Section>
            </Flex>
          </Flex>
        </Content.Wrapper>
      </Content>
      {modal.showAdresModal && (
        <MusteriEklemeModalContainer2
          musteri_id={musteri?.musteri_id}
          vazgecCallback={() => setModal(defaultModalState)}
          callback={musteriModalCallback}
        />
      )}
      {modal.showSiparisModal1 && (
        <SiparisOlusturmaModalContainer1
          siparis={siparis}
          setSiparis={setSiparis}
          defaultModalState={defaultModalState}
          setModal={setModal}
          adresler={adresler}
          musteri_id={musteri?.musteri_id}
        />
      )}
      {modal.showSiparisModal2 && (
        <SiparisOlusturmaModalContainer2
          siparis={siparis}
          setSiparis={setSiparis}
          defaultModalState={defaultModalState}
          defaultSiparisState={defaultSiparisState}
          setModal={setModal}
          getUrun={getUrun}
        />
      )}
      {modal.showSiparisModal3 && (
        <SiparisOlusturmaModalContainer3
          siparis={siparis}
          setSiparis={setSiparis}
          defaultModalState={defaultModalState}
          setModal={setModal}
        />
      )}
      {modal.showSiparisModal4 && (
        <SiparisOlusturmaModalContainer4
          siparis={siparis}
          setSiparis={setSiparis}
          defaultModalState={defaultModalState}
          setModal={setModal}
          getData={getData}
          musteri_id={musteri?.musteri_id}
        />
      )}
      {modal.musteriBilgiModal && (
        <MusteriBilgiModal
          defaultModalState={defaultModalState}
          setModal={setModal}
          musteri_id={musteri?.musteri_id}
          musteri_ad_soyad={musteri?.musteri_ad_soyad}
          musteriModalCallback={musteriModalCallback}
        />
      )}

      {modal.adresBilgiModal && (
        <AdresBilgiModal
          acik_adres={adres?.acik_adres}
          adres_id={adres?.adres_id}
          defaultModalState={defaultModalState}
          setModal={setModal}
          musteriModalCallback={musteriModalCallback}
        />
      )}
    </>
  );
}

export default MusteriProfil;
