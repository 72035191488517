import React from 'react';
import { Container } from './styles/backdrop';
import { useWindowKey } from '../../hooks';

const Backdrop = React.forwardRef(
  ({ children, handler, ...restProps }, ref) => {
    useWindowKey({
      keys: ['Escape'],
      handlers: [handler],
    });

    return (
      <Container ref={ref} {...restProps}>
        {children}
      </Container>
    );
  }
);

export default Backdrop;
