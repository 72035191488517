import styled from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';
import { colors, indexes, radius, screens } from '../../../styles/variables';
import { commonFontSet } from '../../../styles/mixins';

export const Container = styled.div`
  background: #fff;
  border-radius: ${radius.roundedSM};
  box-shadow: 0 10px 50px #0000002e;
  color: ${colors.grayText};
  left: 0;
  position: absolute;
  top: calc(100% + 10px);
  width: 100%;
  z-index: ${indexes.poppedUI};
  `;

export const Wrapper = styled.div`
  margin-right: 16px;
  position: relative;
  width: ${({ width }) => width || '100%'};
`;

export const Button = styled.button`
  ${commonFontSet}
  align-items: center;
  background-color: white;
  border-radius: ${radius.roundedSM};
  color: ${({ variant }) => (variant === 'red' ? colors.red : colors.grayText)};
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 15px 20px;
  text-align: start;
  width: 100%;
  
  &:hover,
  &:focus {
    background-color: ${colors.gray};
  }
`;

export const ToggleButton = styled(Button)`
  padding: 20px;
  width: 100%;
  @media ${screens.xl} {
    padding: 15px;
  }
`;

export const List = styled.ul`
  padding: 16px 15px;
  text-align: start;
  width: 100%;
`;

export const Item = styled.li`
  color: ${({ variant }) => variant || 'inherit'};
`;

export const Link = styled(ReactRouterLink)`
  ${commonFontSet}
  border-radius: ${radius.roundedSM};
  color: inherit;
  display: block;
  padding: 15px;
  outline: none;

  &:hover,
  &:focus {
    background-color: ${colors.gray};
  }
`;

export const Text = styled.p`
  line-height: 0.1;
  margin-right: 18px;
`;
