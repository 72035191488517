import React, { useRef } from 'react';
import {
  Container,
  Wrapper,
  Title,
  SectionTitle,
  Text,
  FormText,
  Box,
  Form,
  Fieldset,
  FieldContainer,
  Label,
  RadioLabel,
  Radio,
  Button,
  ButtonBlue,
  Warn,
  DisplayText,
  IconContainer,
} from './styles/modal';
import { Portal } from '../../helpers';
import { WarnIcon } from '../icons';
function Modal({ children, ...rest }) {
  return (
    <Portal>
      <Container {...rest}>{children}</Container>
    </Portal>
  );
}

Modal.Wrapper = function ModalWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Modal.Title = function ModalTitle({ children, ...rest }) {
  return <Title {...rest}>{children}</Title>;
};

Modal.SectionTitle = function ModalSectionTitle({ children, ...rest }) {
  return <SectionTitle {...rest}>{children}</SectionTitle>;
};

Modal.Text = function ModalText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

Modal.FormText = function ModalFormText({ children, ...rest }) {
  return <FormText {...rest}>{children}</FormText>;
};

Modal.Box = function ModalBox({ children, ...rest }) {
  return <Box {...rest}>{children}</Box>;
};

Modal.Form = function ModalForm({ children, ...rest }) {
  return <Form {...rest}>{children}</Form>;
};

Modal.Fieldset = function ModalFieldset({ children, ...rest }) {
  return <Fieldset {...rest}>{children}</Fieldset>;
};

Modal.FieldContainer = function ModalFieldContainer({ children, ...rest }) {
  return <FieldContainer {...rest}>{children}</FieldContainer>;
};

Modal.Label = function ModalLabel({ children, ...rest }) {
  return <Label {...rest}>{children}</Label>;
};

Modal.Button = function ModalButton({ children, ...rest }) {
  return <Button {...rest}>{children}</Button>;
};

Modal.ButtonBlue = function ModalButtonBlue({ children, ...rest }) {
  return <ButtonBlue {...rest}>{children}</ButtonBlue>;
};

Modal.Radio = function ModalRadio({ ...rest }) {
  return <Radio type='radio' {...rest} />;
};

Modal.RadioLabel = function ModalRadioLabel({ children, ...rest }) {
  const ref = useRef();
  return (
    <RadioLabel {...rest} ref={ref}>
      {children}
    </RadioLabel>
  );
};

Modal.Warn = function ModalWarn({ children, ...rest }) {
  return (
    <Warn {...rest}>
      <DisplayText>{children}</DisplayText>
      <IconContainer>
        <WarnIcon />
      </IconContainer>
    </Warn>
  );
};


export default Modal;
