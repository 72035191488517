import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { GET, POST } from '../constants/endpoints';
import * as ROUTES from '../constants/routes';
import { trimAndFormatNumber } from '../helpers';
import { useMaxHeight, useFetchData, usePostData } from '../hooks';

function useMusterilerLogic(params) {
  const history = useHistory();
  const { sayfa_sayisi, siralama, redirect } = useParams();
  const { handleItemClick } = params;
  const [showModal, setShowModal] = useState(false);
  const [displayedIsımList, setDisplayedIsımList] = useState([]);
  const [aramaAlani, setAramaAlani] = useState('');
  const [aramaAlaniAd, setAramaAlaniAd] = useState('');
  const [aramaType, setAramaType] = useState({ tel: true, ad: false });
  const { data, getData } = useFetchData(
    `${GET.MUSTERI_LISTE_DATA}?page=${sayfa_sayisi}&siralama=${siralama}&q`,
    true
  );
  const { resource, postData } = usePostData();
  const { tableBodyRef, maxHeight } = useMaxHeight(data, 85);
  const handleMusteriEkleClick = () => {
    setShowModal(true);
  };

  const handleYeniMusteriClick = (e) => {
    handleItemClick(e, 'Yeni Müşteri');
  };

  const handleEskiMusteriClick = (e) => {
    handleItemClick(e, 'Eski Müşteri');
  };

  const handleAramaAlaniKeydown = (e) => {
    if (e.key === 'Enter' && /\d{10}/.test(aramaAlani)) {
      history.push(`${ROUTES.MUSTERI_PROFIL_TEL}/${aramaAlani}`);
    }
  };

  const handleAramaAlaniAdChange = (e) => {
    const { value } = e.target;
    setAramaAlaniAd(value);
  };

  const handleAramaAlaniChange = (e) => {
    const { value } = e.target;
    if (/\d/.test(value) || value === '') {
      const formattedValue = trimAndFormatNumber(value);
      if (formattedValue.length <= 10) {
        setAramaAlani(formattedValue);
      }
      return;
    }
    setAramaAlani(value);
    postData(POST.MUSTERI_ARAMA, { q: value });
  };

  const handleCycleAdTelClick = () => {
    const { ad, tel } = aramaType;
    setAramaType({ ad: !ad, tel: !tel });
  };

  const musteriEklemeModalContainer1Callback = () => {
    setShowModal(false);
    if (redirect) {
      history.push(`${ROUTES.MUSTERILER}/1/yeni`);
    }
  };

  const {
    musteriler,
    sayfalama,
    sayfa_sayisi: toplam_sayfa_sayisi,
  } = data?.hits?.data || {};

  useEffect(() => {
    getData(
      `${GET.MUSTERI_LISTE_DATA}?page=${
        sayfa_sayisi || 1
      }&siralama=${siralama}&q`
    );
  }, [sayfa_sayisi, siralama]);

  useEffect(() => {
    if (redirect) setShowModal(true);
  }, [redirect, setShowModal]);

  useEffect(() => {
    const { data } = resource?.resp || {};
    if (data) {
      setDisplayedIsımList(data);
    }
  }, [resource]);

  const handlers = {
    handleYeniMusteriClick,
    handleEskiMusteriClick,
    handleMusteriEkleClick,
    handleAramaAlaniKeydown,
    handleAramaAlaniChange,
    handleAramaAlaniAdChange,
    handleCycleAdTelClick,
  };

  return {
    handlers,
    musteriler,
    sayfalama,
    sayfa_sayisi,
    siralama,
    toplam_sayfa_sayisi,
    tableBodyRef,
    maxHeight,
    showModal,
    setShowModal,
    aramaAlani,
    aramaType,
    aramaAlaniAd,
    musteriEklemeModalContainer1Callback,
    displayedIsımList,
  };
}

export default useMusterilerLogic;
