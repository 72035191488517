import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table } from '../components';
import TimeAgo from 'react-timeago';
import { formatter } from '../constants/formatter';
import * as ROUTES from '../constants/routes';
import { IncomingCallIcon, SmartphoneIcon } from '../components/icons';
import { durumaGoreIconGetir, durumaGoreTextGetir, siparisTuruneGoreIconGetir } from '../helpers';
import { SIPARIS_TUR } from '../constants/siparis-tur';

function MusteriProfilTableContainer({ siparisler }) {
  return (
    <>
      {siparisler.map(
        ({
          teslimat_adresi,
          toplam_tutar,
          urun_sayisi,
          siparis_durum_adi,
          siparis_durum_id,
          siparis_tur_id,
          siparis_tarihi,
          siparis_tarihi_format,
          siparis_id,
        }) => (
          <Table.Row key={uuidv4()}>
            <Table.RowLink to={`${ROUTES.SIPARIS_DETAY}/${siparis_id}`}>
              <Table.Data width='10%'>
                <Table.IconContainer
                  variant={
                    siparis_tur_id === SIPARIS_TUR.CALL_CENTER ? 'purple' : ''
                  }
                >
                  {siparisTuruneGoreIconGetir(siparis_tur_id)}
                </Table.IconContainer>
              </Table.Data>
              <Table.Data width='21%'>
                <Table.Text>{teslimat_adresi[0]}</Table.Text>
                <Table.TextSmall>
                  {teslimat_adresi[1]}/{teslimat_adresi[2]}
                </Table.TextSmall>
              </Table.Data>
              <Table.Data width='19%'>
                <Table.Price>{toplam_tutar}</Table.Price>
                <Table.TextSmall>{urun_sayisi} Adet</Table.TextSmall>
              </Table.Data>
              <Table.Data width='20%'>
                <Table.Text>
                  <TimeAgo
                    date={siparis_tarihi}
                    formatter={formatter}
                    title={siparis_tarihi_format}
                  />
                </Table.Text>
              </Table.Data>
              <Table.Data width='30%'>
                <Table.OrderSituation durum={siparis_durum_id}>
                  <Table.OrderSituationIconContainer>
                    {durumaGoreIconGetir(siparis_durum_id)}
                  </Table.OrderSituationIconContainer>
                  {siparis_durum_adi}
                </Table.OrderSituation>
              </Table.Data>
            </Table.RowLink>
          </Table.Row>
        )
      )}
    </>
  );
}

export default MusteriProfilTableContainer;
