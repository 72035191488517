import React from 'react';
import { Flex, Footer, Menu } from '../components';
import {
  BellIcon,
  ChevronIcon,
  GridIcon,
  PinIcon,
  ShoppingBagIcon,
  UserIcon,
  MessageIcon,
  SettingsIcon,
  LogOutIcon,
  IncomingCallIcon,
} from '../components/icons';
import useMenuLogic from '../hooks/useMenuLogic';
import { ReactComponent as ArtiIkiLogo } from '../assets/img/artiiki-logo-beyaz.svg';
import 'styled-components/macro';
import * as ROUTES from '../constants/routes';

function MenuContainer() {
  const { showTopList, handleMenuBtnClick, handleLogOutClick } = useMenuLogic();

  return (
    <Menu>
      <Menu.Wrapper>
        <Menu.TopWrapper>
          <Menu.ProfileDecor />
          <Menu.TitleWrapper>
            <Menu.Title>Call Center</Menu.Title>
           {/*  <Menu.Text>Merve Meryem KIRIMLI</Menu.Text> */}
          </Menu.TitleWrapper>
        </Menu.TopWrapper>

        <Menu.Nav>
          <Menu.List>
            <Menu.Item active>
              <Menu.ButtonLink to={ROUTES.ANASAYFA}>
                <Menu.IconContainer>
                  <GridIcon />
                </Menu.IconContainer>
                Anasayfa
              </Menu.ButtonLink>
            </Menu.Item>

           {/*  <Menu.Item preventActiveStyle active={showTopList}>
              <Menu.Button onClick={handleMenuBtnClick}>
                <Menu.IconContainer>
                  <UserIcon />
                </Menu.IconContainer>
                Müşteri
                <Menu.ChevronIconContainer>
                  <ChevronIcon />
                </Menu.ChevronIconContainer>
              </Menu.Button>
              <Menu.List>
                <Menu.Item>
                  <Menu.ButtonLink padding='true'>
                    <Menu.EmptyDiv />
                    Müşteri Arama
                  </Menu.ButtonLink>
                </Menu.Item>
                <Menu.Item>
                  <Menu.ButtonLink padding='true'>
                    <Menu.EmptyDiv />
                    Müşteri Ekle
                  </Menu.ButtonLink>
                </Menu.Item>
              </Menu.List>
            </Menu.Item>

            <Menu.Item>
              <Menu.Button>
                <Menu.IconContainer>
                  <ShoppingBagIcon />
                </Menu.IconContainer>
                Sipariş
                <Menu.ChevronIconContainer>
                  <ChevronIcon />
                </Menu.ChevronIconContainer>
              </Menu.Button>
            </Menu.Item>
            <Menu.Item>
              <Menu.Button>
                <Menu.IconContainer>
                  <PinIcon />
                </Menu.IconContainer>
                Bayi
                <Menu.ChevronIconContainer>
                  <ChevronIcon />
                </Menu.ChevronIconContainer>
              </Menu.Button>
            </Menu.Item>

            <Menu.Item>
              <Menu.ButtonLink>
                <Menu.IconContainer>
                  <BellIcon />
                </Menu.IconContainer>
                Bildirimler
              </Menu.ButtonLink>
            </Menu.Item>
            <Menu.Item>
              <Menu.ButtonLink>
                <Menu.IconContainer>
                  <MessageIcon />
                </Menu.IconContainer>
                Mesaj
                <Menu.Badge>1</Menu.Badge>
              </Menu.ButtonLink>
            </Menu.Item>
            <Menu.Item>
              <Menu.ButtonLink>
                <Menu.IconContainer>
                  <IncomingCallIcon />
                </Menu.IconContainer>
                Cevapsız Çağrı
              </Menu.ButtonLink>
            </Menu.Item> */}
            <Menu.Item>
              <Menu.ButtonLink>
                <Menu.IconContainer>
                  <SettingsIcon />
                </Menu.IconContainer>
                Ayarlar
              </Menu.ButtonLink>
            </Menu.Item>
            <Menu.Item>
              <Menu.ButtonLink onClick={handleLogOutClick}>
                <Menu.IconContainer>
                  <LogOutIcon />
                </Menu.IconContainer>
                Çıkış Yap
              </Menu.ButtonLink>
            </Menu.Item>
          </Menu.List>
        </Menu.Nav>

        <Menu.BottomWrapper>
          <Footer css='padding:0'>
            <Flex justify='center'>
              <Footer.Link href='https://artiiki.com/'>
                <ArtiIkiLogo />
              </Footer.Link>
            </Flex>
          </Footer>
        </Menu.BottomWrapper>
      </Menu.Wrapper>
    </Menu>
  );
}

export default MenuContainer;
