import React from 'react';
import {
  Container,
  Row,
  Card,
  CardTitle,
  TitleText,
  Text,
  Button
} from './styles/info';

function Info({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

Info.Row = function InfoRow({ children, ...rest }) {
  return <Row {...rest}>{children}</Row>;
};

Info.Card = function InfoCard({ children, ...rest }) {
  return <Card {...rest}>{children}</Card>;
};

Info.CardTitle = function InfoCardTitle({ children, ...rest }) {
  return <CardTitle {...rest}>{children}</CardTitle>;
};

Info.TitleText = function InfoTitleText({ children, ...rest }) {
  return <TitleText {...rest}>{children}</TitleText>;
};

Info.Text = function InfoText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

Info.Button = function InfoButton({ children, ...rest }) {
  return <Button {...rest}>{children}</Button>;
};

export default Info;
