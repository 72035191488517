import styled from 'styled-components/macro';
import { commonFontSet } from '../../../styles/mixins';
import {
  colors,
  fonts,
  fontSizes,
  margin,
  radius,
} from '../../../styles/variables';

export const Card = styled.div`
  ${({ variant }) =>
    variant === 'green' && `border: 2px solid ${colors.green};`}
  background-color: ${colors.gray};
  border-radius: ${radius.roundedSM};
  margin-top: ${margin.common};
  padding: 15px 20px;
  position: relative;
`;

export const Container = styled.div`
  & ${Card}:first-child {
    margin: 0;
  }
`;

export const Row = styled.div`
  border-radius: ${radius.roundedSM};
  display: flex;
  justify-content: space-between;
  padding: 20px;

  &:hover {
    background-color: ${colors.gray};
  }
`;

export const CardTitle = styled.h3`
  ${commonFontSet}
  color: ${colors.darkBlue};
`;

export const TitleText = styled.p`
  ${commonFontSet}
  color: ${colors.grayText};
`;

export const Text = styled.p`
  color: ${({ variant }) =>
    variant === 'gray' ? colors.gray3 : colors.darkBlue};
  font-family: ${fonts.roboto};
  font-size: ${fontSizes.md};
`;

export const Button = styled.button`
  background-color: transparent;
  color: ${colors.gray3};
  font-size: ${fontSizes.xl};
`;
