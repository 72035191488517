import styled from 'styled-components/macro';
import { colors, margin, radius, screens, transitions } from '../../../styles/variables';
import { commonFontSet } from '../../../styles/mixins';

export const Container = styled.button`
  ${commonFontSet}
  align-items: center;
  background-color: ${colors.blue};
  border-radius: ${radius.roundedSM};
  border-radius: 10px;
  color: white;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : margin.common2)};
  padding: 15px 30px;
  transition: background-color 100ms;
  outline: none;
  width: ${({ width }) => width || 'auto'};

  &:hover,
  &:focus {
    background-color: ${colors.blueHover};
  }

  &:disabled {
    background-color: ${colors.blueTransparent};
    cursor: not-allowed;
  }

  @media ${screens.lg} {
    padding: 15px;
  }
`;

export const Gray = styled(Container)`
  background-color: ${colors.gray};
  color: ${colors.grayText};

  &:hover,
  &:focus {
    background-color: ${colors.grayHover2};
  }
`

export const Dashed = styled.button`
  ${commonFontSet}
  background-color: transparent;
  border: 2px dashed ${colors.gray5};
  border-radius: ${radius.roundedSM};
  color: ${colors.grayText};
  padding: 20px 0;
  text-align: center;
  transition: ${transitions.buttonBgHover};
  width: 100%;

  &:hover,
  &:focus {
    background-color: #efefef;
  }
`;
