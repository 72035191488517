import React from 'react';
import {
  Container,
  Wrapper,
  Card,
  TopWrapper,
  ContentWrapper,
  TrendingIconContainer,
  IconContainer,
  Searchbox,
  StatContainer,
  StatText,
  Text,
  Title,
  Count,
  ButtonLink,
} from './styles/dashboard';

function Dashboard({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

Dashboard.Wrapper = function DashboardWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};
Dashboard.Card = function DashboardCard({ children, ...rest }) {
  return <Card {...rest}>{children}</Card>;
};
Dashboard.TopWrapper = function DashboardTopWrapper({ children, ...rest }) {
  return <TopWrapper {...rest}>{children}</TopWrapper>;
};
Dashboard.ContentWrapper = function DashboardContentWrapper({
  children,
  ...rest
}) {
  return <ContentWrapper {...rest}>{children}</ContentWrapper>;
};
Dashboard.TrendingIconContainer = function DashboardTrendingIconContainer({
  children,
  ...rest
}) {
  return <TrendingIconContainer {...rest}>{children}</TrendingIconContainer>;
};

Dashboard.IconContainer = function DashboardIconContainer({
  children,
  ...rest
}) {
  return <IconContainer {...rest}>{children}</IconContainer>;
};
Dashboard.Searchbox = function DashboardSearchbox({ children, ...rest }) {
  return <Searchbox {...rest}>{children}</Searchbox>;
};
Dashboard.StatContainer = function DashboardStatContainer({
  children,
  ...rest
}) {
  return <StatContainer {...rest}>{children}</StatContainer>;
};
Dashboard.StatText = function DashboardStatText({ children, ...rest }) {
  return <StatText {...rest}>{children}</StatText>;
};
Dashboard.Text = function DashboardText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};
Dashboard.Title = function DashboardTitle({ children, ...rest }) {
  return <Title {...rest}>{children}</Title>;
};
Dashboard.Count = function DashboardCount({ children, ...rest }) {
  return <Count {...rest}>{children}</Count>;
};
Dashboard.ButtonLink = function DashboardButtonLink({ children, ...rest }) {
  return <ButtonLink {...rest}>{children}</ButtonLink>;
};

export default Dashboard;
