import React from 'react';
import { Container, TextWrapper,Wrapper, Title, Text } from './styles/img';

function Img({ src, ...rest }) {
  return <Container src={src} {...rest} />;
}

Img.Wrapper = function ImgWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Img.Title = function ImgTitle({ children, ...rest }) {
  return <Title {...rest}>{children}</Title>;
};

Img.TextWrapper = function ImgTextWrapper({ children, ...rest }) {
  return <TextWrapper {...rest}>{children}</TextWrapper>;
};

Img.Text = function ImgText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

export default Img;
