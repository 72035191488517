import { useContext, useRef } from 'react';
import { CircleCheckIcon, CircleXIcon } from '../components/icons';
import { DialogContainer } from '../containers';
import { DialogContext } from '../context';
import * as DIALOGS from '../constants/dialogs';
import useWindowKey from './useWindowKey';
import { Dialog } from '../components';
import 'styled-components/macro';

function useDialogLogic(params) {
  const { dialog, setDialog } = useContext(DialogContext);
  const btnRef = useRef(null);

  const resetDialog = () => {
    setDialog({ active: false, type: '', callback: null });
  };
  useWindowKey({
    keys: ['Escape'],
    handlers: [resetDialog],
    condition: dialog,
  });

  const activateDialog = (e, type, callback) => {
    if (e) e.stopPropagation();
    params?.dialogActivationHandler();
    setDialog({ active: true, type, callback });
  };

  const handleOnayClick = (e) => {
    e.stopPropagation();
    dialog?.callback && dialog.callback();
    resetDialog();
  };

  const chooseNotificationDialog = () => {
    switch (dialog.type) {
      case DIALOGS.TESLIM_EDILDI:
        return (
          <DialogContainer
            title='Teslim Edildi'
            text='Siparişi başarıyla Teslim Edildi olarak işaretlediniz.'
            icon={<CircleCheckIcon />}
            variant='green'
          />
        );
      case DIALOGS.TESLIM_EDILEMEDI:
        return (
          <DialogContainer
            title='Teslim Edilemedi'
            text='Siparişi Teslim Edilemedi
            olarak işaretlediniz. '
            icon={<CircleXIcon />}
            variant='red'
          />
        );
      case DIALOGS.SIPARIS_IPTAL:
        return (
          <DialogContainer
            title='Siparişi İptali'
            text='Siparişi İptal Ettiniz.'
            icon={<CircleXIcon />}
            variant='red'
            handleOnayClick={handleOnayClick}
            noBtn={
              <Dialog.Button
                onClick={() => resetDialog()}
                btnRef={btnRef}
                css='margin:auto; margin-right: 0 !important; '
              >
                Kapat
              </Dialog.Button>
            }
            yesBtn={false}
            btnRef={btnRef}
          />
        );
      default:
        break;
    }
  };

  const chooseActionDialog = () => {
    switch (dialog.type) {
      case DIALOGS.TESLIM_EDILDI:
        return (
          <DialogContainer
            title='Teslim Edildi Olarak İşaretle'
            text='Siparişi teslim edildi olarak işaretlemek
        istediğinize emin misiniz?'
            icon={<CircleCheckIcon />}
            variant='green'
          />
        );
      case DIALOGS.DEGISIKLIKLERI_KAYDET:
        return (
          <DialogContainer
            title='Değişiklikleri Kaydet'
            text='Sipariş’te yapmış olduğunuz değişiklikleri
            kaydetmek istediğinize emin misiniz?'
            icon={<CircleCheckIcon />}
            variant='blue'
          />
        );
      case DIALOGS.SIPARIS_IPTAL:
        return (
          <DialogContainer
            title='Siparişi İptal Et'
            text='Siparişi iptal etmek istediğinize
            emin misiniz?'
            icon={<CircleXIcon />}
            variant='red'
            handleOnayClick={handleOnayClick}
          />
        );
      default:
        break;
    }
  };

  return {
    dialog,
    chooseNotificationDialog,
    chooseActionDialog,
    activateDialog,
    resetDialog,
  };
}

export default useDialogLogic;
