import React from 'react';
import { Container, Wrapper, Text, Title, Slim } from './styles/content';

function Content({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

Content.Wrapper = function ContentWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Content.Title = function ContentTitle({ children, ...rest }) {
  return <Title {...rest}>{children}</Title>;
};

Content.Text = function ContentTitle({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

Content.Slim = function ContentSlim({ children, ...rest }) {
  return <Slim {...rest}>{children}</Slim>;
};

export default Content;
