import { useReducer } from "react";
import { urunReducer } from "../reducers";

function useUrunLogic(defaultState = []) {
  const [urunState, dispatch] = useReducer(urunReducer, defaultState);

  const increaseAmount = (id) => {
    dispatch({ type: "INCREASE_AMOUNT", payload: id });
  };

  const decreaseAmount = (id) => {
    dispatch({ type: "DECREASE_AMOUNT", payload: id });
  };

  const removeData = (id) => {
    dispatch({ type: "REMOVE_DATA", payload: id });
  };

  const changeDepozito = (id,depozito) => {
    dispatch({ type: "CHANGE_DEPOZITO", payload: {id,depozito} });
  };

  const init = (data) => dispatch({ type: "INIT", payload: data });

  return {
    increaseAmount,
    decreaseAmount,
    changeDepozito,
    removeData,
    urunState,
    init,
  };
}

export default useUrunLogic;
