import React from 'react';
import 'styled-components/macro';
import { Backdrop, Form, Modal } from '../../components';
import { useSiparisNotuDuzenlemeLogic } from '../../hooks';

function SiparisNotuDuzenlemeModal(props) {
  const { siparis_notu, getData, closeModal } = props;
  const { handlers, siparisNotu, warn } = useSiparisNotuDuzenlemeLogic({
    siparis_notu,
    getData,
    closeModal,
  });

  const { handleSubmit, handleVazgecClick, handleSiparisNotuChange } = handlers;
  return (
    <>
      <Backdrop handler={handleVazgecClick} />
      <Modal>
        <Modal.Wrapper>
          <Modal.SectionTitle>Sipariş Notunu Düzenle</Modal.SectionTitle>
          <Modal.Form onSubmit={handleSubmit}>
            <Modal.FieldContainer>
              <div css='width:100%'>
                <Form.Label htmlFor='input-1'>Sipariş Notu</Form.Label>
                <Form.Input
                  onChange={handleSiparisNotuChange}
                  id='input-1'
                  name='input-1'
                  type='text'
                  placeholder={''}
                  value={siparisNotu}
                />
              </div>
              {warn.siparis_notu && <Form.Warn>{warn.siparis_notu}</Form.Warn>}
            </Modal.FieldContainer>
            <Modal.Box>
              <Modal.Button autoFocus onClick={handleVazgecClick}>
                Vazgeç
              </Modal.Button>
              <Modal.ButtonBlue style={{whiteSpace:"pre"}}>
                Değişiklikleri Kaydet
              </Modal.ButtonBlue>
            </Modal.Box>
          </Modal.Form>
        </Modal.Wrapper>
      </Modal>
    </>
  );
}

export default SiparisNotuDuzenlemeModal;
