import { useHistory, useParams } from 'react-router';
import { GET, POST } from '../../../constants/endpoints';
import usePostData from '../../usePostData';
import * as ROUTES from '../../../constants/routes';

function useSiparisOlusturmaModal4Logic(params) {
  const { siparis, setModal, defaultModalState, getData, musteri_id } = params;
  const history = useHistory();
  const siparisPostData = {
    adres_id: siparis.adres_id,
    urunler: siparis.urunler,
  };
  const { resource, postData } = usePostData(
    POST.SIPARIS_OZET,
    true,
    siparisPostData
  );

  const handleVazgecClick = () => {
    setModal({ ...defaultModalState });
  };
  const handleIlerleClick = () => {
    postData(POST.SIPARIS_OLUSTUR, siparis, () =>
      getData(`${GET.MUSTERI_DETAY_DATA}?musteri_id=${musteri_id}`)
    );
    history.push(`${ROUTES.MUSTERI_PROFIL}/${musteri_id}`);
    setModal({ ...defaultModalState });
  };
  const {
    toplam_urun,
    depozito_toplam_fiyat,
    indirim_toplam_fiyat,
    sepet_toplam_fiyat,
  } = resource?.resp?.data || {};
  const handlers = {
    handleIlerleClick,
    handleVazgecClick,
  };

  const siparisOzet = {
    toplam_urun,
    depozito_toplam_fiyat,
    indirim_toplam_fiyat,
    sepet_toplam_fiyat,
  };
  return { handlers, siparisOzet };
}

export default useSiparisOlusturmaModal4Logic;
