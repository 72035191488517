import { useContext } from 'react';
import { MusteriContext } from '../context';

function useMusteri() {
  const { currentMusteri, setCurrentMusteri } = useContext(MusteriContext);

  const siparisIdEkle = (id) => {
    setCurrentMusteri({ ...currentMusteri, siparis_id: id });
  };
  return { currentMusteri, setCurrentMusteri, siparisIdEkle };
}

export default useMusteri;
