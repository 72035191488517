import { colors } from '../variables';

const variantColorGetir = (variant, lightBg = false, lightIcon = false) => {
  switch (variant) {
    case 'gray':
      return lightBg ? colors.gray : colors.grayText;
    case 'red':
      return !lightBg || lightIcon ? colors.red : colors.redLight;
    case 'green':
      return !lightBg || lightIcon ? colors.green : colors.greenLight;
    case 'blue':
      return !lightBg || lightIcon ? colors.blue : colors.blueLight;
    default:
      break;
  }
};

export default variantColorGetir;
