
  export const getItem = (key) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (err) {
      console.log(err);
    }
    return null;
  };

  export const setItem = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      console.log(err);
    }
  };

  export const removeItem = (key) => {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      console.log(err);
    }
  };

  export const pushItem = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify([...getItem(key), value]));
    } catch (err) {
      console.log(err);
    }
  };


