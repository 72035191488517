import React from 'react';
import {
  Container,
  ImgContainer,
  Img,
  Box,
  Text,
  Price,
  Button,
  CounterContainer,
  CounterText,
  TextContainer
} from './styles/urun';

function Urun({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

Urun.ImgContainer = function UrunImgContainer({ children, ...rest }) {
  return <ImgContainer {...rest}>{children}</ImgContainer>;
};

Urun.Img = function UrunImg({ src, children, ...rest }) {
  return <Img src={src} {...rest} />;
};

Urun.Box = function UrunBox({ children, ...rest }) {
  return <Box {...rest}>{children}</Box>;
};

Urun.Text = function UrunText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

Urun.Price = function UrunPrice({ children, ...rest }) {
  return <Price {...rest}>{children}</Price>;
};

Urun.Button = function UrunButton({ children, ...rest }) {
  return <Button {...rest}>{children}</Button>;
};

Urun.CounterContainer = function UrunCounterContainer({ children, ...rest }) {
  return <CounterContainer {...rest}>{children}</CounterContainer>;
};

Urun.CounterText = function UrunCounterText({ children, ...rest }) {
  return <CounterText {...rest}>{children}</CounterText>;
};

Urun.TextContainer = function UrunTextContainer({ children, ...rest }) {
  return <TextContainer {...rest}>{children}</TextContainer>;
};

export default Urun;
