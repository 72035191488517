import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import usePostData from '../usePostData';
import { POST } from '../../constants/endpoints';
import { setItem } from '../../helpers/manageLocalStorage';
import { useAuth } from '../../hooks';

const defaultWarn = {
  username: '',
  password: '',
};

function useGirisFormLogic() {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [warn, setWarn] = useState(defaultWarn);
  const { resource, postData } = usePostData();
  const { setIsAuth } = useAuth();
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    if (/(\d)/.test(value[value.length - 1]) || !value)
      setPassword(e.target.value);
  };

  const handlePasswordToggleClick = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (inputlariKontrolEt()) return;
    const kullaniciGirisInfo = {
      kullanici_adi: username,
      sifre: password,
    };
    postData(POST.UYE_GIRISI, kullaniciGirisInfo);
  };

  const inputlariKontrolEt = () => {
    const warnObj = { ...defaultWarn };
    if (!username) warnObj.username = 'Kullanıcı adı alanını boş bırakmayınız';
    if (!password) warnObj.password = 'Parola alanını boş bırakmayınız';
    if (warnObj.username || warnObj.password) {
      setWarn(warnObj);
      return true;
    }
    setWarn(defaultWarn);
  };

  const responseErrorKontrolEt = useCallback(() => {
    const {
      error: { response },
    } = resource;
    const warnObj = { ...defaultWarn };
    if (response.data.sifre) warnObj.password = response.data.sifre;
    if (response.data.error) warnObj.username = response.data.error;

    if (warnObj.username || warnObj.password) return setWarn(warnObj);
    setWarn(defaultWarn);
  }, [resource]);

  useEffect(() => {
    if (resource?.resp) {
      setIsAuth(true);
      return setItem('token', resource.resp.data.token);
    }

    if (resource.error) return responseErrorKontrolEt();
  }, [resource, history, setIsAuth, responseErrorKontrolEt]);

  return {
    showPassword,
    password,
    username,
    handleUsernameChange,
    handlePasswordChange,
    handlePasswordToggleClick,
    handleFormSubmit,
    resource,
    warn,
  };
}

export default useGirisFormLogic;
