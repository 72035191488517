import React from 'react';
import 'styled-components/macro';
import { Backdrop, Flex, Modal } from '../../../components';
import { useSiparisOlusturmaModal2Logic } from '../../../hooks';
import SiparisUrunContainer from '../../siparis-urun';
function SiparisOlusturmaModalContainer2(props) {
  const {
    siparis,
    setSiparis,
    defaultModalState,
    defaultSiparisState,
    setModal,
    getUrun,
    musteri_id,
  } = props;
  const { handlers, urunLogic, urunler, warn } = useSiparisOlusturmaModal2Logic(
    {
      defaultModalState,
      defaultSiparisState,
      setModal,
      getUrun,
      siparis,
      setSiparis,
      musteri_id,
    }
  );
  const { handleIlerleClick, handleVazgecClick } = handlers;
  const { urunState, increaseAmount, decreaseAmount, changeDepozito } =
    urunLogic;

  return (
    <>
      <Backdrop handler={handleVazgecClick} />
      <Modal style={{ maxHeight: '80vh', overflow: 'auto' }}>
        <Modal.Wrapper>
          <Modal.Title>2 / 4</Modal.Title>
          <Flex align='flex-start' direction='column'>
            <Modal.FieldContainer>
              <Modal.SectionTitle>Sipariş Oluştur</Modal.SectionTitle>
              <Modal.Text>
                Aşağıdaki ürünlerden seçim yaparak sipariş oluşturabilirsiniz.
              </Modal.Text>
              {warn.depozito && <Modal.Warn>{warn.depozito}</Modal.Warn>}
              {urunState.some((urun) => urun.adet) && (
                <Modal.ButtonBlue
                  onClick={handleIlerleClick}
                  style={{ width: '100%', marginTop: '20px' }}
                >
                  İlerle
                </Modal.ButtonBlue>
              )}
            </Modal.FieldContainer>
            <Modal.FieldContainer style={{ width: '100%' }}>
              {warn.genel ? (
                <Modal.Warn>{warn.genel}</Modal.Warn>
              ) : (
                <Modal.SectionTitle style={{ marginBottom: '20px' }}>
                  Ürünler ({urunler?.length || ''})
                </Modal.SectionTitle>
              )}
            </Modal.FieldContainer>
            <Flex direction='column'>
              {urunState?.length
                ? urunState.map((urun, i) => (
                    <SiparisUrunContainer
                      key={urun.urun_id}
                      noMargin={i === 0}
                      autoFocus={i === 0}
                      urun={urun}
                      increaseAmount={increaseAmount}
                      decreaseAmount={decreaseAmount}
                      changeDepozito={changeDepozito}
                    />
                  ))
                : null}
            </Flex>
          </Flex>
          <Modal.Box style={{ width: '100%' }}>
            <Modal.Button onClick={handleVazgecClick}>Vazgeç</Modal.Button>
            {/* !!! Herhangi bir ürün seçiliyse gösterilecek */}
            {urunState.some((urun) => urun.adet) && (
              <Modal.ButtonBlue onClick={handleIlerleClick}>
                İlerle
              </Modal.ButtonBlue>
            )}
          </Modal.Box>
        </Modal.Wrapper>
      </Modal>
    </>
  );
}

export default SiparisOlusturmaModalContainer2;
