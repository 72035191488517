import React from "react";
import { Flex, Urun } from "../components";
import { colors } from "../styles/variables";
import "styled-components/macro";

function UrunContainer(props) {
  const { noMargin = false } = props;
  const {
    urun_adi,
    birim_fiyati,
    adet,
    kampanya,
    indirimsiz_birim_fiyati,
    urun_gorsel,
  } = props.urun;
  return (
    <Urun noMargin={noMargin}>
      <Flex justify="space-between">
        <Flex justify="space-between">
          {urun_gorsel ? (
            <Urun.ImgContainer>
              {<Urun.Img src={urun_gorsel} css="width:100px;" />}
            </Urun.ImgContainer>
          ) : <Urun.ImgContainer css="width:125px; background-color:transparent">
            </Urun.ImgContainer>}
          <Flex direction="column" align="flex-start">
            <Urun.Text color={colors.darkBlue}>{urun_adi}</Urun.Text>
            <Flex align="flex-start">
              {kampanya && (
                <Urun.Price old>{indirimsiz_birim_fiyati}</Urun.Price>
              )}
              <Urun.Price>{birim_fiyati}</Urun.Price>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify="flex-end">
          <Urun.Text>{adet} Adet</Urun.Text>
        </Flex>
      </Flex>
    </Urun>
  );
}

export default UrunContainer;
