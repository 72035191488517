import React, { useEffect } from "react";
import { Flex, Form, Modal, Urun } from "../components";
import { colors } from "../styles/variables";

function SiparisUrunContainer(props) {
  const {
    noMargin = false,
    urun,
    increaseAmount,
    decreaseAmount,
    changeDepozito,
    autoFocus
  } = props;
  const {
    urun_id,
    urun_adi,
    urun_indirimsiz_fiyat,
    urun_fiyat,
    urun_gorsel,
    kampanya,
    depozito,
    adet,
  } = urun;

  const handleDepozitoChange = (e, id) => {
    changeDepozito(id, e.target.value);
  };

  return (
    <Urun noMargin={noMargin}>
      <Flex align="flex-start" justify="space-between">
        <Flex align="flex-start" justify="space-between">
          <Urun.ImgContainer>
            <Urun.Img src={urun_gorsel} />
          </Urun.ImgContainer>
          <Flex direction="column" align="flex-start">
            <Urun.Text color={colors.darkBlue}>{urun_adi}</Urun.Text>
            <Flex align="flex-start">
              {kampanya && <Urun.Price old>{urun_indirimsiz_fiyat}</Urun.Price>}
              <Urun.Price>{urun_fiyat}</Urun.Price>
            </Flex>
            {depozito && adet ? (
              <div>
                <Urun.Text color={colors.darkBlue}>
                  Boş Damacana (Depozito)
                </Urun.Text>
                <Modal.Fieldset
                  onChange={(e) => handleDepozitoChange(e, urun_id)}
                >
                  <Modal.RadioLabel
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    Var
                    <Modal.Radio name={`depozito_${urun_id}`} value="false" />
                  </Modal.RadioLabel>
                  <Modal.RadioLabel
                    style={{ border: "none", backgroundColor: "transparent" }}
                  >
                    Yok
                    <Modal.Radio name={`depozito_${urun_id}`} value="true" />
                  </Modal.RadioLabel>
                </Modal.Fieldset>
              </div>
            ) : null}
          </Flex>
        </Flex>
        <Flex justify="flex-end">
          <Urun.Box>
            <Urun.CounterContainer>
              <Urun.Button onClick={() => decreaseAmount(urun_id)}>
                -
              </Urun.Button>
              <Urun.CounterText>{adet} Adet</Urun.CounterText>
              <Urun.Button autoFocus={autoFocus} onClick={() => increaseAmount(urun_id)}>
                +
              </Urun.Button>
            </Urun.CounterContainer>
          </Urun.Box>
        </Flex>
      </Flex>
    </Urun>
  );
}

export default SiparisUrunContainer;
