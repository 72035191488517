import {
  Anasayfa,
  Giris,
  Musteriler,
  MusteriProfil,
  SiparisDetay,
  TumSiparisler,
} from './pages';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MenuContainer from './containers/menu';
import * as ROUTES from './constants/routes';
import AuthProvider from './providers/auth';
import { IsUserRedirect, ProtectedRoute } from './helpers/routes';
import { DialogProvider, ModalProvider, MusteriProvider } from './providers';

function App() {
  return (
    <AuthProvider>
      <ModalProvider>
        <DialogProvider>
          <MusteriProvider>
            <Router>
              <Switch>
                <ProtectedRoute path={ROUTES.ANASAYFA} exact>
                  <Anasayfa />
                  <MenuContainer />
                </ProtectedRoute>
                <ProtectedRoute path={`${ROUTES.TUM_SIPARISLER}/:sayfa_sayisi/:siparis_durum_id`}>
                  <TumSiparisler />
                  <MenuContainer />
                </ProtectedRoute>
                <ProtectedRoute exact path={`${ROUTES.MUSTERILER}/:sayfa_sayisi/:siralama`}>
                  <Musteriler />
                  <MenuContainer />
                </ProtectedRoute>
                <ProtectedRoute exact path={`${ROUTES.MUSTERILER_REDIRECTED}/:redirect/:sayfa_sayisi/:siralama/:telefon`}>
                  <Musteriler />
                  <MenuContainer />
                </ProtectedRoute>
                <ProtectedRoute path={`${ROUTES.SIPARIS_DETAY}/:siparis_id`}>
                  <SiparisDetay />
                  <MenuContainer />
                </ProtectedRoute>
                <ProtectedRoute exact path={`${ROUTES.MUSTERI_PROFIL}/:musteri_id`}>
                  <MusteriProfil />
                  <MenuContainer />
                </ProtectedRoute>
                <ProtectedRoute path={`${ROUTES.MUSTERI_PROFIL_TEL}/:telefon`}>
                  <MusteriProfil />
                  <MenuContainer />
                </ProtectedRoute>
                <IsUserRedirect
                  loggedInPath={ROUTES.ANASAYFA}
                  path={ROUTES.GIRIS}
                >
                  <Giris />
                </IsUserRedirect>
              </Switch>
            </Router>
          </MusteriProvider>
        </DialogProvider>
      </ModalProvider>
    </AuthProvider>
  );
}

export default App;
