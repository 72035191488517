import React from 'react';
import {
  Container,
  Wrapper,
  Box,
  InputWrapper,
  Input,
  Label,
  Title,
  IconContainer,
  TextWrapper,
  Text,
  TextSmall,
  Fieldset,
  Warn,
  Button
} from './styles/form';

function Form({ children, ...rest }) {
  return <Container autoComplete="off" {...rest}>{children}</Container>;
}

Form.Wrapper = function FormWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Form.Box = function FormBox({ children, ...rest }) {
  return <Box {...rest}>{children}</Box>;
};

Form.InputWrapper = function FormInputWrapper({ children, ...rest }) {
  return <InputWrapper {...rest}>{children}</InputWrapper>;
};

Form.Fieldset = function FormFieldset({ children, ...rest }) {
  return <Fieldset {...rest}>{children}</Fieldset>;
};

Form.Title = function FormTitle({ children, ...rest }) {
  return <Title {...rest}>{children}</Title>;
};

Form.Label = function FormLabel({ children, ...rest }) {
  return <Label {...rest}>{children}</Label>;
};

Form.TextWrapper = function FormTextWrapper({ children, ...rest }) {
  return <TextWrapper {...rest}>{children}</TextWrapper>;
};

Form.Text = function FormText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

Form.TextSmall = function FormTextSmall({ children, ...rest }) {
  return <TextSmall {...rest}>{children}</TextSmall>;
};

Form.IconContainer = function FormIconContainer({ children, ...rest }) {
  return <IconContainer {...rest}>{children}</IconContainer>;
};

Form.Button = function FormButton({children, ...rest }) {
  return <Button {...rest} >{children}</Button>;
};

Form.Warn = function FormWarn({children, ...rest }) {
  return <Warn {...rest} >{children}</Warn>;
};

Form.Input = function FormInput({ ...rest }) {
  return <Input autoComplete="off" {...rest} />;
};

export default Form;
