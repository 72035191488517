import React from 'react';
import 'styled-components/macro';
import { Backdrop, Flex, Info, Modal } from '../../../components';
import { useSiparisOlusturmaModal1Logic } from '../../../hooks';

function SiparisOlusturmaModalContainer1(props) {
  const { siparis, setSiparis, defaultModalState, setModal,musteri_id } = props;
  const { handlers, data } = useSiparisOlusturmaModal1Logic({
    siparis,
    setSiparis,
    defaultModalState,
    setModal,
    musteri_id
  });
  const {
    handleIlerleClick,
    handleVazgecClick,
    handleRadioChange,
    handleSubmit,
  } = handlers;

  // !!! Style'ları css'e çevir. Webpack bug'ini çöz.
  return (
    <>
      <Backdrop handler={handleVazgecClick} />
      <Modal style={{ maxHeight: '80vh', overflow: 'auto' }}>
        <Modal.Wrapper>
          <Modal.Title>1 / 4</Modal.Title>
          <Modal.Form onSubmit={handleSubmit}>
            <Modal.FieldContainer>
              <Modal.SectionTitle>Sipariş Oluştur</Modal.SectionTitle>
            </Modal.FieldContainer>
            <Modal.FieldContainer>
              <Modal.SectionTitle>Adresler</Modal.SectionTitle>
              <Modal.Text>
                Seçili olan adres varsayılan adres olarak kabul edilir.
                Varsayılan adresi değiştirebilir ya da yeni bir adres
                oluşturabilirsiniz.
              </Modal.Text>
              <Modal.Fieldset
                onChange={handleRadioChange}
                style={{ gridTemplateColumns: 'repeat(1, minmax(0, 1fr))' }}
              >
                {data?.hits?.data?.adresler?.map(
                  ({ adres_id, adres_adi, teslimat_adresi, varsayilan }) => (
                    <Modal.RadioLabel
                      key={adres_id}
                      style={{
                        width: '100%',
                        border: `${
                          varsayilan || siparis.adres_id === adres_id
                            ? '2px solid #19CD92'
                            : ''
                        }`,
                      }}
                    >
                      <Modal.Radio
                        name='adres'
                        value={adres_id}
                        style={{ marginRight: '25px' }}
                        defaultChecked={varsayilan}
                      />
                      <Flex align='flex-start' direction='column'>
                        <Info.CardTitle>Adres ({adres_adi})</Info.CardTitle>
                        <Info.Text>{teslimat_adresi[0]}</Info.Text>
                        <Info.Text>{teslimat_adresi[1]}</Info.Text>
                      </Flex>
                    </Modal.RadioLabel>
                  )
                )}
              </Modal.Fieldset>
            </Modal.FieldContainer>
            <Modal.Box>
              <Modal.Button autoFocus onClick={handleVazgecClick}>Vazgeç</Modal.Button>
              <Modal.ButtonBlue onClick={handleIlerleClick}>
                İlerle
              </Modal.ButtonBlue>
            </Modal.Box>
          </Modal.Form>
        </Modal.Wrapper>
      </Modal>
    </>
  );
}

export default SiparisOlusturmaModalContainer1;
