import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Content, Dropdown, Flex, Form, Section } from '../components';
import {
  useDialogLogic,
  useDropdownLogic,
  useSiparisDetayLogic,
} from '../hooks';
import { colors, screens } from '../styles/variables';
import { ChevronIcon, PinIcon } from '../components/icons';
import 'styled-components/macro';
import {
  AddressContainer,
  SiparisDurumContainer,
  SiparisNotuDuzenlemeModal,
  UrunContainer,
} from '../containers';
import SiparisOzet from '../containers/siparis-ozet';
import * as DIALOGS from '../constants/dialogs';
import TeslimatDurumModalContainer from '../containers/modals/teslimat-durum-modal';
import { SIPARIS_DURUM } from '../constants/siparis-durum';

function SiparisDetay() {
  const { showDropdown, closeDropdown, aktifItem, handleToggleClick } =
    useDropdownLogic('Sipariş İşlemleri');
  const {
    dialog,
    chooseActionDialog,
    chooseNotificationDialog,
    activateDialog,
  } = useDialogLogic({
    dialogActivationHandler: closeDropdown,
  });

  const {
    handlers,
    siparisiIptalEt,
    bayi,
    siparis,
    urunler,
    musteri,
    durumlar,
    modal,
    getData,
    resource,
    closeModal,
  } = useSiparisDetayLogic({ closeDropdown, activateDialog });

  const { handleSiparisNotuClick, handleTeslimatDurumClick } = handlers;
  return (
    <Content>
      <Content.Wrapper>
        <Flex justify='space-between' align='flex-start'>
          <Flex
            direction='column'
            align='flex-start'
            width='auto'
            css='margin-right:1em; flex-shrink:10'
          >
            <Content.Title>Sipariş Detay</Content.Title>
            <Content.Text>
              Tüm detaylara ulaşabilir, ürün ekleyebilir/çıkartabilir, siparişi
              iptal edebilirsiniz.
            </Content.Text>
          </Flex>
          <Form.Box>
            {siparis?.siparis_durum_id ===
              SIPARIS_DURUM.TESLIMAT_BEKLENIYOR && (
              <Dropdown.Wrapper width='330px'>
                <Dropdown.ToggleButton onClick={handleToggleClick}>
                  <Dropdown.Text>{aktifItem}</Dropdown.Text>
                  <ChevronIcon />
                </Dropdown.ToggleButton>
                {showDropdown && (
                  <Dropdown>
                    <Dropdown.List>
                      <Dropdown.Item>
                        <Dropdown.Button
                          onClick={handleTeslimatDurumClick}
                          css={`
                            color: ${colors.darkBlue};
                          `}
                        >
                          Teslimat Durumunu Değiştir
                        </Dropdown.Button>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Dropdown.Button
                          onClick={handleSiparisNotuClick}
                          css={`
                            color: ${colors.darkBlue};
                          `}
                        >
                          Sipariş Notunu Düzenle
                        </Dropdown.Button>
                      </Dropdown.Item>
                      <Dropdown.Item variant={colors.red}>
                        <Dropdown.Button
                          onClick={(e) =>
                            activateDialog(
                              e,
                              DIALOGS.SIPARIS_IPTAL,
                              siparisiIptalEt
                            )
                          }
                          variant='red'
                          css={`
                            min-width: 260px;
                          `}
                        >
                          Siparişi İptal Et
                        </Dropdown.Button>
                      </Dropdown.Item>
                    </Dropdown.List>
                  </Dropdown>
                )}
              </Dropdown.Wrapper>
            )}
          </Form.Box>
        </Flex>
      </Content.Wrapper>
      <Content.Wrapper paddingBottom='30px'>
        <Flex align='flex-start'>
          <Flex
            direction='column'
            align='flex-start'
            flexBasis='100%'
            css='margin-right:30px;'
          >
            <Section noMargin>
              <Section.Wrapper>
                <Flex>
                  <Section.IconContainer css='margin-right:14px;'>
                    <PinIcon />
                  </Section.IconContainer>
                  <Flex align='flex-start' direction='column'>
                    <Section.Title>{bayi?.bayi_adi}</Section.Title>
                    <Section.Text>{bayi?.arac_plaka}</Section.Text>
                  </Flex>
                </Flex>
                <Section.Inner>
                  <Flex
                    align='stretch'
                    css={`
                      @media ${screens.lg2} {
                        flex-direction: column !important;
                        & > div:last-child {
                          margin-top: 10px;
                        }
                      }
                    `}
                  >
                    {siparis ? (
                      <>
                        <SiparisDurumContainer
                          siparis_durum_id='siparisAlindi'
                          siparis_durum_adi='Sipariş Alındı'
                          siparis_tarihi={siparis.siparis_tarihi}
                          siparis_tarihi_format={siparis.siparis_tarihi_format}
                        />
                        <SiparisDurumContainer
                          siparis_durum_id={siparis.siparis_durum_id}
                          siparis_durum_adi={siparis.siparis_durum_adi}
                          teslimat_turu={siparis.teslimat_turu}
                          teslimat_tarihi={siparis.teslimat_tarihi}
                          noMargin
                        />
                      </>
                    ) : null}
                  </Flex>
                </Section.Inner>
              </Section.Wrapper>
            </Section>
            <Section>
              <Section.Wrapper>
                <Flex>
                  <Flex align='flex-start' direction='column'>
                    <Section.Title>Adres Bilgileri</Section.Title>
                  </Flex>
                </Flex>
                <Section.Inner>
                  {musteri ? (
                    <AddressContainer
                      musteri={musteri}
                      siparis_notu={siparis?.siparis_notu || ''}
                    />
                  ) : null}
                </Section.Inner>
              </Section.Wrapper>
            </Section>
          </Flex>
          <Flex direction='column' align='flex-start' flexBasis='100%'>
            <Section noMargin width='100%'>
              <Section.Wrapper>
                <Flex align='flex-start' direction='column'>
                  <Section.Title>Ürünler</Section.Title>
                  <Section.Text>
                    {siparis?.urun_sayisi || 0} adet ürün bulunmaktadır.
                  </Section.Text>
                </Flex>
                <Section.Inner>
                  <Flex align='flex-start' direction='column'>
                    {urunler.length
                      ? urunler.map((urun, i) => (
                          <UrunContainer
                            key={uuidv4()}
                            noMargin={i === 0}
                            urun={urun}
                          />
                        ))
                      : null}
                  </Flex>
                </Section.Inner>
              </Section.Wrapper>
            </Section>
            <Section>
              <Section.Wrapper>
                <Section.Inner>
                  {siparis ? (
                    <SiparisOzet
                      urun_sayisi={siparis.urun_sayisi}
                      toplam_tutar={siparis.toplam_tutar}
                      indirim_tutari={siparis.indirim_tutari}
                      depozito_tutari={siparis.depozito_tutari}
                    />
                  ) : null}
                </Section.Inner>
              </Section.Wrapper>
            </Section>
          </Flex>
        </Flex>
      </Content.Wrapper>
      {dialog.active
        ? resource.resp?.data?.success
          ? chooseNotificationDialog()
          : chooseActionDialog()
        : null}
      {modal.showTeslimatDurum && (
        <TeslimatDurumModalContainer
          durumlar={durumlar}
          closeModal={closeModal}
          getData={getData}
        />
      )}
      {modal.showSiparisNotu && (
        <SiparisNotuDuzenlemeModal
          closeModal={closeModal}
          getData={getData}
          siparis_notu={siparis?.siparis_notu}
        />
      )}
    </Content>
  );
}

export default SiparisDetay;
