import React from 'react';
import { SiparisOzetContainer } from '../..';
import { Backdrop, Modal } from '../../../components';
import { useSiparisOlusturmaModal4Logic } from '../../../hooks';

function SiparisOlusturmaModalContainer4(props) {
  const { siparis, defaultModalState, setModal, getData,musteri_id } = props;
  const { handlers, siparisOzet } = useSiparisOlusturmaModal4Logic({
    siparis,
    setModal,
    defaultModalState,
    getData,
    musteri_id
  });
  const {
    toplam_urun,
    sepet_toplam_fiyat,
    indirim_toplam_fiyat,
    depozito_toplam_fiyat,
  } = siparisOzet;
  const { handleIlerleClick, handleVazgecClick } = handlers;
  return (
    <>
      <Backdrop handler={()=>setModal(defaultModalState)} />
      <Modal>
        <Modal.Wrapper>
          <Modal.Title>4 / 4</Modal.Title>
          <Modal.FieldContainer>
            <Modal.SectionTitle>Sipariş Oluştur</Modal.SectionTitle>
            <Modal.Text>
              Sipariş verebilmek için lütfen sepeti onaylayınız.
            </Modal.Text>
          </Modal.FieldContainer>
          <Modal.FieldContainer>
            <Modal.SectionTitle>Sipariş Özeti</Modal.SectionTitle>
            {toplam_urun && (
              <>
                <SiparisOzetContainer
                  urun_sayisi={toplam_urun}
                  toplam_tutar={sepet_toplam_fiyat}
                  indirim_tutari={indirim_toplam_fiyat}
                  depozito_tutari={depozito_toplam_fiyat}
                />
                <Modal.Box style={{ width: '100%' }}>
                  <Modal.Button onClick={handleVazgecClick}>
                    Vazgeç
                  </Modal.Button>
                  <Modal.ButtonBlue onClick={handleIlerleClick}>
                    Siparişi Onayla
                  </Modal.ButtonBlue>
                </Modal.Box>
              </>
            )}
          </Modal.FieldContainer>
        </Modal.Wrapper>
      </Modal>
    </>
  );
}

export default SiparisOlusturmaModalContainer4;
