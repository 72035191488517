import styled from 'styled-components/macro';
import { commonFontSet } from '../../../styles/mixins';
import { colors, fontSizes, screens, sizes } from '../../../styles/variables';

export const Container = styled.main`
  background-color: ${colors.grayBg};
  display: flex;
  flex-direction: column;
  margin-left: ${sizes.menuWidth};
  min-height: 100vh;
`;

export const Wrapper = styled.div`
  padding: ${({ padding }) => padding || '40px 30px'};
  ${({ paddingTop }) =>
    paddingTop ? `padding-top: ${paddingTop}` : undefined};
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? paddingBottom : 0)};
  ${({ paddingLeft }) =>
    paddingLeft ? `padding-left: ${paddingLeft}` : undefined};
  ${({ paddingRight }) =>
    paddingRight ? `padding-right: ${paddingRight}` : undefined};
  margin: ${({ margin }) => margin};
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}` : undefined)};
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom}` : undefined};
  ${({ marginLeft }) =>
    marginLeft ? `margin-left: ${marginLeft}` : undefined};
  ${({ marginRight }) =>
    marginRight ? `margin-right: ${marginRight}` : undefined};
`;

export const Title = styled.h1`
  ${commonFontSet}
  color: ${colors.darkBlue};
  font-size: ${fontSizes.lg};
`;

export const Text = styled.h2`
  ${commonFontSet}
  color: ${colors.grayText};
`;

export const Slim = styled.span`
  font-weight: 400;
`;
