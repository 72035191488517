import styled from 'styled-components/macro';

export const Container = styled.footer`
  line-height: 1.4em;
  /*   margin-top: auto; */
  padding: 30px;
  position: relative;
  width: 100%;
  z-index: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Link = styled.a`
  color: inherit;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: color 100ms;
`;

export const Text = styled.p`
  display: inline-block;
  @media (max-width: 64em) {
    text-align: center;
  }
`;

export const Span = styled.span`
  margin-right: 0.7em;
`;

export const Img = styled.img``;
