import styled from 'styled-components/macro';
import { colors, fontSizes, fonts, radius } from '../../../styles/variables';

export const Container = styled.section`
  margin-top: ${({ noMargin }) => (noMargin ? '0' : '15px')};
  width:${({width})=> width || "100%"};
`;

export const Wrapper = styled.div``;

export const Inner = styled.div`
  background-color: white;
  border-radius: ${radius.roundedMD};
  margin-top: 25px;
  padding: 20px;
`;

export const Title = styled.h2`
  color: ${colors.darkBlue};
  font-size: ${fontSizes.lg};
  font-weight: 500;
`;

export const Text = styled.p`
  color: ${colors.grayText};
  font-size: ${fontSizes.md};
  font-family: ${fonts.roboto};
`;

export const IconContainer = styled.div`
  background-color: ${colors.gray2};
  border-radius: 50%;
  display: flex;
  font-size: ${fontSizes.lg};
  padding: 12px;
`;
