import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { useAuth } from '../hooks';

export function IsUserRedirect({ loggedInPath, children, ...rest }) {
  const { isAuth } = useAuth();

  return (
    <Route
      {...rest}
      render={() => {
        if (!isAuth) {
          return children;
        }

        if (isAuth) {
          return (
            <Redirect
              to={{
                pathname: loggedInPath,
              }}
            />
          );
        }

        return null;
      }}
    />
  );
}

export function ProtectedRoute({ children, ...rest }) {
  const { isAuth } = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuth) {
          return children;
        }

        if (!isAuth) {
          return (
            <Redirect
              to={{
                pathname: ROUTES.GIRIS,
                state: { from: location },
              }}
            />
          );
        }

        return null;
      }}
    />
  );
}
