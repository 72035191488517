import React from 'react';
import {
  Content,
  Flex,
  Form,
  Pagination,
  Table,
  Button,
  Dropdown,
  Controls,
} from '../components';
import { ChevronIcon, RefreshIcon } from '../components/icons';
import {
  MusteriTableContainer,
  MusteriEklemeModalContainer1,
  DropdownSearchbox,
} from '../containers';
import 'styled-components/macro';
import { useDropdownLogic, useMusterilerLogic } from '../hooks';
import * as ROUTES from '../constants/routes';
import { fonts } from '../styles/variables';

function Musteriler() {
  const { showDropdown, aktifItem, handleItemClick, handleToggleClick } =
    useDropdownLogic('Yeni Müşteri');

  const {
    handlers,
    musteriler,
    sayfalama,
    sayfa_sayisi,
    siralama,
    toplam_sayfa_sayisi,
    tableBodyRef,
    maxHeight,
    showModal,
    aramaAlani,
    displayedIsımList,
    musteriEklemeModalContainer1Callback,
  } = useMusterilerLogic({ handleItemClick });

  const {
    handleYeniMusteriClick,
    handleEskiMusteriClick,
    handleMusteriEkleClick,
    handleAramaAlaniKeydown,
    handleAramaAlaniChange,
  } = handlers;

  return (
    <>
      <Content>
        <Content.Wrapper>
          <Content.Title>Müşteri Listesi</Content.Title>
        </Content.Wrapper>
        <Content.Wrapper paddingTop='30px'>
          <Flex justify='space-between' align='flex-end'>
            {/* <Pagination.Text>Mevcut Sayfa: {sayfa_sayisi}</Pagination.Text> */}
            <Form.Box>
              <DropdownSearchbox
                handleKeyDown = {handleAramaAlaniKeydown}
                list={displayedIsımList}
                placeholder='Arama Alanı'
                css={`
                  margin-top: 0;
                  margin-right: 1em;
                  font-weight: 500;
                  width: 300px;
                `}
                inputCSS={`font-family:${fonts.poppins};`}
                handleChange={handleAramaAlaniChange}
                value={aramaAlani}
                autoFocus
              >
                {displayedIsımList?.map(({ musteri_id, isim,telefon }) => (
                  <Dropdown.Item key={musteri_id}>
                    <Dropdown.Link
                      to={`${ROUTES.MUSTERI_PROFIL}/${musteri_id}`}
                      css='min-width:initial; width:100%;'
                    >
                      {isim}
                      <p>{telefon}</p>
                    </Dropdown.Link>
                  </Dropdown.Item>
                ))}
              </DropdownSearchbox>
            </Form.Box>
            <Form.Box css='margin-left:auto;'>
              <Dropdown.Wrapper>
                <Dropdown.ToggleButton onClick={handleToggleClick}>
                  <Dropdown.Text>Filtre: {aktifItem}</Dropdown.Text>
                  <ChevronIcon />
                </Dropdown.ToggleButton>
                {showDropdown && (
                  <Dropdown>
                    <Dropdown.List>
                      <Dropdown.Item>
                        <Dropdown.Link
                          to={`${ROUTES.MUSTERILER}/1/yeni`}
                          onClick={handleYeniMusteriClick}
                        >
                          Yeni Müşteri
                        </Dropdown.Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Dropdown.Link
                          to={`${ROUTES.MUSTERILER}/1/eski`}
                          onClick={handleEskiMusteriClick}
                        >
                          Eski Müşteri
                        </Dropdown.Link>
                      </Dropdown.Item>
                    </Dropdown.List>
                  </Dropdown>
                )}
              </Dropdown.Wrapper>
              <Button onClick={handleMusteriEkleClick} noMargin>
                Müşteri Ekle
              </Button>
            </Form.Box>
          </Flex>
        </Content.Wrapper>
        <Content.Wrapper paddingTop='0'>
          <Table.Wrapper maxHeight='70vh'>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.DataHead width='22%'>Ad Soyad</Table.DataHead>
                  <Table.DataHead width='21%'>Telefon Numarası</Table.DataHead>
                  <Table.DataHead width='21%'>Kayıt Tarihi</Table.DataHead>
                  <Table.DataHead width='21%'>Varsayılan Adres</Table.DataHead>
                  <Table.DataHead width='15%'></Table.DataHead>
                </Table.Row>
              </Table.Head>
              {musteriler?.length ? (
                <Table.Body ref={tableBodyRef} maxHeight={maxHeight}>
                  <MusteriTableContainer musteriler={musteriler} />
                </Table.Body>
              ) : null}
            </Table>
          </Table.Wrapper>
        </Content.Wrapper>
        <Pagination.Wrapper>
          <Flex justify='space-between'>
            <Pagination.Text>
              Toplam {toplam_sayfa_sayisi} sayfa bulundu
            </Pagination.Text>
            <Pagination>
              <Pagination.LinkContainer>
                {sayfalama?.prev && (
                  <Pagination.PageLink
                    to={`${ROUTES.MUSTERILER}/${sayfalama.prev}/${siralama}`}
                    css='margin-right:60px;'
                  >
                    <Pagination.IconContainer
                      direction='left'
                      css='margin-right:5px;'
                    >
                      <ChevronIcon />
                    </Pagination.IconContainer>
                    Önceki
                  </Pagination.PageLink>
                )}
                {/*   <Pagination.PageLink
                  to={`${ROUTES.MUSTERILER}/${toplam_sayfa_sayisi}/${siralama}`}
                >Son sayfaya git</Pagination.PageLink> */}
                {sayfalama?.next && (
                  <Pagination.PageLink
                    to={`${ROUTES.MUSTERILER}/${sayfalama.next}/${siralama}`}
                    noMargin
                  >
                    Sonraki
                    <Pagination.IconContainer
                      direction='right'
                      css='margin-left:5px;'
                    >
                      <ChevronIcon />
                    </Pagination.IconContainer>
                  </Pagination.PageLink>
                )}
              </Pagination.LinkContainer>
            </Pagination>
          </Flex>
        </Pagination.Wrapper>
      </Content>
      {showModal && (
        <MusteriEklemeModalContainer1
          callback={musteriEklemeModalContainer1Callback}
        />
      )}
    </>
  );
}

export default Musteriler;
