import React, { useState } from 'react';
import { DialogContext } from '../context';

function DialogProvider({ children, ...rest }) {
  const [dialog, setDialog] = useState({
    active: false,
    type: '',
    callback: null,
  });

  return (
    <DialogContext.Provider value={{ dialog, setDialog }} {...rest}>
      {children}
    </DialogContext.Provider>
  );
}

export default DialogProvider;
