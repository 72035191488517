import { useState } from 'react';
import { useAuth } from '.';
import { LOCAL_KEYS } from '../constants/localKeys';
import { removeItem } from '../helpers/manageLocalStorage';

function useMenuLogic() {
  const [showTopList, setShowTopList] = useState(false);
  const { setIsAuth } = useAuth();

  const handleMenuBtnClick = () => {
    setShowTopList(!showTopList);
  };

  const handleLogOutClick = () => {
    setIsAuth(false);
    removeItem(LOCAL_KEYS.TOKEN);
  };
  return { showTopList, handleMenuBtnClick,handleLogOutClick };
}

export default useMenuLogic;
