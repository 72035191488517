import React, { useState } from 'react';
import { LOCAL_KEYS } from '../constants/localKeys';
import { AuthContext } from '../context';
import { getItem } from '../helpers/manageLocalStorage';

function AuthProvider({ children, ...rest }) {
  const [isAuth, setIsAuth] = useState(getItem(LOCAL_KEYS.TOKEN));
 
  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth }} {...rest}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
