import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { LOCAL_KEYS } from '../constants/localKeys';
import { getItem } from '../helpers/manageLocalStorage';

const defaultState = {
  resp: null,
  error: null,
  isLoading: false,
};

let request = null;

function usePostData(defaultUrl, auto = false, defaultData) {
  request = axios.CancelToken.source();
  const [resource, setResource] = useState(defaultState);
  const [isMount, setIsMount] = useState(false);
  const postData = useCallback(
    (url, data, callback) => {
      setResource({ ...defaultState, isLoading: true });
      axios(
        {
          method: 'POST',
          url,
          data,
          headers: {
            Authorization: `Bearer ${getItem(LOCAL_KEYS.TOKEN)}`,
          },
        },
        { cancelToken: request.token }
      )
        .then((resp) => setResource({ ...resource, resp }))
        .then(() => callback && callback())
        .catch((error) => setResource({ ...resource, error }));
    },
    [resource]
  );

  useEffect(() => {
    if (auto && !isMount) {
      postData(defaultUrl, defaultData);
      setIsMount(true);
    }

    return () => request?.cancel();
  }, [auto, postData, defaultUrl, defaultData, isMount]);

  return { postData, resource };
}

export default usePostData;
