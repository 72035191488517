import { useCallback, useEffect, useState } from 'react';
import { GET, POST } from '../../constants/endpoints';
import useFetchData from '../useFetchData';
import usePostData from '../usePostData';
const debounce = require('lodash.debounce');

const defaultAdresAramaState = {
  il_adi: '',
  ilce_adi: '',
  mahalle_adi: '',
};

const defaultAdresState = {
  musteri_id: '',
  il_id: '',
  ilce_id: '',
  semt_id: '',
  mahalle_id: '',
  adres_adi: '',
  acik_adres: '',
  yol_tarifi: '',
  adlar: {
    il_adi: '',
    ilce_adi: '',
    mahalle_adi: '',
  },
};

const adresAdMap = {
  il_adi: 'il_adi',
  ilce_adi: 'ilce_adi',
  mahalle_adi: 'mahalle_adi',
};

const defaultWarn = {
  il_id: '',
  ilce_id: '',
  mahalle_id: '',
  acik_adres: '',
  adres_adi: '',
};

function useMusteriEklemeModal2Logic(musteri_id, callback) {
  const [adresArama, setAdresArama] = useState(defaultAdresAramaState);
  const [ilList, setIlList] = useState([]);
  const [ilceList, setIlceList] = useState([]);
  const [displayedIlList, setDisplayedIlList] = useState([]);
  const [displayedIlceList, setDisplayedIlceList] = useState([]);
  const [displayedMahalleList, setDisplayedMahalleList] = useState([]);
  const [warn, setWarn] = useState(defaultWarn);
  const [adres, setAdres] = useState({ ...defaultAdresState, musteri_id });
  const { data, getData } = useFetchData();
  const { resource, postData } = usePostData();

  const filterListByString = (list, itemKey, string) => {
    return list.filter((item) =>
      item[itemKey].toLocaleLowerCase().includes(string.toLocaleLowerCase())
    ); // !!! Problem çıkartabilir error handling yap.
  };

  const resetWarn = () => {
    setWarn(defaultWarn);
  };

  const handleIlChange = (e) => {
    const { value } = e.target;
    setAdresArama({ ...adresArama, il_adi: value });
    if (ilList.length) setDisplayedIlList(filterListByString(ilList, 'il_adi', value));
  };

  const handleIlceChange = (e) => {
    const { value } = e.target;
    setAdresArama({ ...adresArama, ilce_adi: value });
    if (ilceList.length)
      setDisplayedIlceList(filterListByString(ilceList, 'ilce_adi', value));
  };

  const handleMahalleChange = (e) => {
    const { value } = e.target;
    setAdresArama({ ...adresArama, mahalle_adi: value });
    getData(
      `${GET.MAHALLE_LISTE_DATA}?ilce_id=${adres.ilce_id}&q=${
        value || adresArama.mahalle_adi
      }`
    );
  };

  const handleAdresAdiChange = (e) => {
    setWarn({ ...warn, adres_adi: '' });
    setAdres({ ...adres, adres_adi: e.target.value });
  };

  const handleAcikAdresChange = (e) => {
    setAdres({ ...adres, acik_adres: e.target.value });
  };

  const handleYolTarifiChange = (e) => {
    setAdres({ ...adres, yol_tarifi: e.target.value });
  };

  const handleSearchboxFocus = (url) => {
    getData(url);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetWarn();
    const {
      musteri_id,
      il_id,
      ilce_id,
      semt_id,
      mahalle_id,
      adres_adi,
      acik_adres,
      yol_tarifi,
    } = adres;
    const purgedAdresData = {
      musteri_id,
      il_id,
      ilce_id,
      semt_id,
      mahalle_id,
      acik_adres,
      yol_tarifi,
      adres_adi,
      varsayilan: false,
    };
    if (inputlariKontrolEt()) {
      postData(`${POST.ADRES_EKLEME}`, purgedAdresData, callback);
    }
  };

  const handleIlItemClick = (e, il_id, il_adi) => {
    e.preventDefault();
    setWarn({ ...warn, il_id: '' });
    setAdresArama({ ...adresArama, il_adi });
    setAdres({ ...adres, il_id });
  };

  const handleIlceItemClick = (e, ilce_id, ilce_adi) => {
    e.preventDefault();
    setWarn({ ...warn, ilce_id: '' });
    setAdresArama({ ...adres, ilce_adi });
    setAdres({ ...adres, ilce_id });
  };

  const handleMahalleItemClick = (e, mahalle_id, mahalle_adi, semt_id) => {
    e.preventDefault();
    setWarn({ ...warn, mahalle_id: '' });
    setAdresArama({ ...adres, mahalle_adi });
    setAdres({ ...adres, mahalle_id, semt_id });
  };

  const populateList = (listeAdi, data) => {
    const listObj = {
      il_adi: () => {
        setIlList(data);
        setDisplayedIlList(data);
      },
      ilce_adi: () => {
        setIlceList(data);
        setDisplayedIlceList(data);
      },
      mahalle_adi: () => {
        setDisplayedMahalleList(data);
      },
    };
    if (listObj[listeAdi]) {
      listObj[listeAdi]();
    }
  };

  const inputlariKontrolEt = () => {
    const { il_id, ilce_id, mahalle_id, adres_adi } = adres;
    const errObj = {};
    if (!il_id) errObj.il_id = 'İl alanı boş bırakılamaz!';
    if (!ilce_id) errObj.ilce_id = 'İlçe alanı boş bırakılamaz!';
    if (!mahalle_id) errObj.mahalle_id = 'Mahalle alanı boş bırakılamaz!';
    if (!adres_adi) errObj.adres_adi = 'Adres başlığı alanı boş bırakılamaz!';
    if (Object.keys(errObj).length) {
      setWarn(errObj);
      return false;
    }
    return true;
  };

  const responseErrorKontrolEt = useCallback(() => {
    const warnObj = { ...defaultWarn };
    const { response } = resource?.error || {};
    const { acik_adres, adres_adi } = response?.data || {};
    if (acik_adres) {
      warnObj.acik_adres = acik_adres;
    }
    if (adres_adi) {
      warnObj.adres_adi = adres_adi;
    }
    if (warnObj.acik_adres || warnObj.adres_adi) setWarn(warnObj);
  }, [resource]);

  useEffect(() => {
    const listData = data?.hits?.data?.data;
    if (listData && listData[0]) {
      const keys = Object.keys(listData[0]);
      const listeAdi = keys.find((key) => adresAdMap[key]);
      populateList(listeAdi, listData);
    }
  }, [data]);

  useEffect(() => {
    responseErrorKontrolEt();
  }, [resource, responseErrorKontrolEt]);

  const displayedLists = {
    displayedIlList,
    setDisplayedIlList,
    displayedIlceList,
    setDisplayedIlceList,
    displayedMahalleList,
    setDisplayedMahalleList,
  };

  const handlers = {
    handleIlChange,
    handleIlceChange,
    handleMahalleChange,
    handleAdresAdiChange,
    handleAcikAdresChange,
    handleYolTarifiChange,
    handleIlItemClick,
    handleIlceItemClick,
    handleMahalleItemClick,
    handleSubmit,
    handleSearchboxFocus,
  };
  return {
    handlers,
    displayedLists,
    adres,
    setAdres,
    adresArama,
    setAdresArama,
    warn,
  };
}

export default useMusteriEklemeModal2Logic;
