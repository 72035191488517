const GET = {
  DASHBOARD_DATA: 'https://api.gumus.com.tr/crm/dash/dash',
  SIPARIS_DETAY_DATA: 'https://api.gumus.com.tr/crm/siparis/siparisDetay',
  MUSTERI_DETAY_DATA: 'https://api.gumus.com.tr/crm/musteri/musteriDetay',
  IL_LISTE_DATA: 'https://api.gumus.com.tr/crm/adres/iller',
  ILCE_LISTE_DATA: 'https://api.gumus.com.tr/crm/adres/ilceler',
  MAHALLE_LISTE_DATA: 'https://api.gumus.com.tr/crm/adres/mahalleAra',
  MUSTERI_LISTE_DATA: 'https://api.gumus.com.tr/crm/musteri/musteriListesi',
  MUSTERI_ADRES_LISTE_DATA:
    'https://api.gumus.com.tr/crm/siparis/musteriAdresListesi',
  TUM_SIPARISLER_DATA: 'https://api.gumus.com.tr/crm/siparis/siparisListesi',
  SIPARIS_URUN_LISTE_DATA: 'https://api.gumus.com.tr/crm/siparis/urunListesi',
};

const POST = {
  UYE_GIRISI: 'https://api.gumus.com.tr/crm/auth/giris',
  TESLIMAT_DURUMU:
    'https://api.gumus.com.tr/crm/siparis/siparisDurumGuncelleme',
  MUSTERI_EKLEME: 'https://api.gumus.com.tr/crm/musteri/musteriKayit',
  ADRES_EKLEME: 'https://api.gumus.com.tr/crm/musteri/adresKayit',
  SIPARIS_OZET: 'https://api.gumus.com.tr/crm/siparis/siparisOzeti',
  SIPARIS_OLUSTUR: 'https://api.gumus.com.tr/crm/siparis/siparisOlustur',
  MUSTERI_ARAMA: 'https://api.gumus.com.tr/crm/musteri/musteriArama',
  MUSTERI_ISIM_GUNCELLEME:
    'https://api.gumus.com.tr/crm/musteridev/musteriIsimGuncelleme',
  MUSTERI_ADRES_DUZENLEME:
    'https://api.gumus.com.tr/crm/musteridev/musteriAdresGuncelleme',
  SIPARIS_NOTU_DUZENLEME:
    'https://api.gumus.com.tr/crm/siparis/siparisNotuDuzenleme',
};

export { GET, POST };
