import React from 'react';
import 'styled-components/macro';
import { Dialog, Flex } from '../components';
import { CircleXIcon } from '../components/icons';
import { colors } from '../styles/variables';

function SiparisYokContainer() {
  return (
    <Flex css="height:100%;">
      <Flex justify='center' direction='column' css='max-width:314px; margin:auto;'>
        <Flex justify='center'>
          <Dialog.IconContainer
            css={`
              background-color: ${colors.red};
              box-shadow: 0 0 0 15px ${colors.redLight};
            `}
          >
            <CircleXIcon />
          </Dialog.IconContainer>
        </Flex>
        <Flex direction='column' css='margin-top:40px;'>
          <Dialog.Title>Sipariş Yok</Dialog.Title>
          <Dialog.Text>
            Müşterinin daha önce vermiş olduğu sipariş bulunmamaktadır.
          </Dialog.Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SiparisYokContainer;
