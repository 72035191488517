import React from 'react';
import {
  Container,
  Wrapper,
  Nav,
  List,
  Item,
  ButtonLink,
  Button,
  ChevronIconContainer,
  IconContainer,
  Badge,
  BadgeCount,
  TitleWrapper,
  TopWrapper,
  BottomWrapper,
  Title,
  Text,
  ProfileDecor,
  EmptyDiv,
} from './styles/menu';

function Menu({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

Menu.Wrapper = function MenuWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Menu.Nav = function MenuNav({ children, ...rest }) {
  return <Nav {...rest}>{children}</Nav>;
};

Menu.List = function MenuList({children, ...rest }) {
  return <List {...rest}>{children}</List>;
};

Menu.Item = function MenuItem({ children, ...rest }) {
  return <Item {...rest}>{children}</Item>;
};

Menu.ButtonLink = function MenuButtonLink({ children, ...rest }) {
  return <ButtonLink {...rest}>{children}</ButtonLink>;
};

Menu.Button = function MenuButton({ children, ...rest }) {
  return <Button {...rest}>{children}</Button>;
};

Menu.ChevronIconContainer = function MenuChevronIconContainer({
  children,
  ...rest
}) {
  return <ChevronIconContainer {...rest}>{children}</ChevronIconContainer>;
};

Menu.IconContainer = function MenuIconContainer({ children, ...rest }) {
  return <IconContainer {...rest}>{children}</IconContainer>;
};

Menu.Badge = function MenuBadge({ children, ...rest }) {
  return (
    <Badge {...rest}>
      <BadgeCount>{children}</BadgeCount>
    </Badge>
  );
};

Menu.TopWrapper = function MenuTopWrapper({ children, ...rest }) {
  return <TopWrapper {...rest}>{children}</TopWrapper>;
};

Menu.BottomWrapper = function MenuBottomWrapper({ children, ...rest }) {
  return <BottomWrapper {...rest}>{children}</BottomWrapper>;
};

Menu.TitleWrapper = function MenuTitleWrapper({ children, ...rest }) {
  return <TitleWrapper {...rest}>{children}</TitleWrapper>;
};

Menu.Title = function MenuTitle({ children, ...rest }) {
  return <Title {...rest}>{children}</Title>;
};

Menu.Text = function MenuText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

Menu.ProfileDecor = function MenuProfileDecor({ children, ...rest }) {
  return <ProfileDecor {...rest}>{children}</ProfileDecor>;
};

Menu.EmptyDiv = function MenuEmptyDiv({ children, ...rest }) {
  return <EmptyDiv {...rest}>{children}</EmptyDiv>;
};

export default Menu;
