function urunReducer(state, action) {
  const id = action.payload;
  switch (action.type) {
    case "INCREASE_AMOUNT": {
      return state.map((data) => {
        return data.urun_id === id ? { ...data, adet: data.adet + 1 } : data;
      });
    }
    case "DECREASE_AMOUNT": {
      const data = state.find(({ urun_id: compareId }) => id === compareId);
      if (data.adet > 0) {
        return state.map((data) =>
          data.urun_id === id ? { ...data, adet: data.adet - 1 } : data
        );
      }
      return state;
    }
    case "REMOVE_DATA": {
      return [...state.filter((product) => product.id !== id)];
    }

    case "INIT": {
      return [...action.payload];
    }

    case "CHANGE_DEPOZITO": {
      return state.map((data) =>
        data.urun_id === action.payload.id ? { ...data, depozito: action.payload.depozito } : data
      );
    }
    default:
      console.log(`Unhandled action type ${action.type}`);
      return;
  }
}

export default urunReducer;
