import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { GET, POST } from '../constants/endpoints';
import * as DIALOGS from '../constants/dialogs';
import { SIPARIS_DURUM } from '../constants/siparis-durum';
import useFetchData from './useFetchData';
import usePostData from './usePostData';

const defaultModal = {
  showTeslimatDurum: false,
  showSiparisNotu: false,
};

function useSiparisDetayLogic(args) {
  const { closeDropdown, activateDialog } = args;
  const [modal, setModal] = useState(defaultModal);
  const { siparis_id } = useParams();

  const { getData, data } = useFetchData(
    `${GET.SIPARIS_DETAY_DATA}?siparis_id=${siparis_id}`,
    true
  );
  const { resource, postData } = usePostData();
  
  const siparisiIptalEt = () => {
    postData(
      POST.TESLIMAT_DURUMU,
      {
        siparis_id,
        teslimat_durum_id: SIPARIS_DURUM.CAGRI_MERKEZI_IPTALI,
        teslim_edilememe_durum_id: 0,
      },
      () => getData(`${GET.SIPARIS_DETAY_DATA}?siparis_id=${siparis_id}`)
    );
  };
  const {
    bayi,
    siparis,
    urunler = [],
    musteri,
    durumlar,
  } = data?.hits?.data || {};

  const handleTeslimatDurumClick = () => {
    setModal({ ...modal, showTeslimatDurum: true });
    closeDropdown();
  };

  const handleSiparisNotuClick = () => {
    setModal({ ...modal, showSiparisNotu: true });
    closeDropdown();
  };

  const closeModal = ()=> {
    setModal(defaultModal)
  }

  useEffect(() => {
    if (resource.resp?.data?.success) {
      activateDialog(false, DIALOGS.SIPARIS_IPTAL);
    }
  }, [resource.resp?.data?.success]);

  const handlers = {
    handleSiparisNotuClick,
    handleTeslimatDurumClick,
  };
  return {
    handlers,
    siparisiIptalEt,
    bayi,
    siparis,
    urunler,
    musteri,
    durumlar,
    modal,
    getData,
    resource,
    closeModal
  };
}

export default useSiparisDetayLogic;
