import React from 'react';
import {
  Container,
  Wrapper,
  List,
  Item,
  Link,
  Button,
  ToggleButton,
  Text,
} from './styles/dropdown';

function Dropdown({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

Dropdown.Wrapper = function DropdownWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Dropdown.List = function DropdownList({ children, ...rest }) {
  return <List {...rest}>{children}</List>;
};

Dropdown.Item = function DropdownItem({ children, ...rest }) {
  return <Item {...rest}>{children}</Item>;
};

Dropdown.Link = function DropdownLink({ children, ...rest }) {
  return <Link {...rest}>{children}</Link>;
};

Dropdown.Button = function DropdownButton({ children, ...rest }) {
  return <Button {...rest}>{children}</Button>;
};

Dropdown.ToggleButton = function DropdownToggleButton({ children, ...rest }) {
  return <ToggleButton {...rest}>{children}</ToggleButton>;
};

Dropdown.Text = function DropdownText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

export default Dropdown;
