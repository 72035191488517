import React, { useEffect } from "react";
import {
  Content,
  Dropdown,
  Flex,
  Form,
  Button,
  Table,
  Pagination,
  Controls,
} from "../components";
import { ChevronIcon } from "../components/icons";
import {
  TumSiparislerTableContainer,
} from "../containers";
import "styled-components/macro";
import { useDropdownLogic, useFetchData, useMaxHeight } from "../hooks";
import * as ROUTES from "../constants/routes";
import { GET } from "../constants/endpoints";
import { useParams } from "react-router";
import { SIPARIS_DURUM } from "../constants/siparis-durum";

function TumSiparisler() {
  const { showDropdown, aktifItem, handleItemClick, handleToggleClick } =
    useDropdownLogic("Teslimat Bekleyenler");
  const { sayfa_sayisi, siparis_durum_id } = useParams();
  const { data, getData } = useFetchData(
    `${GET.TUM_SIPARISLER_DATA}?page=${sayfa_sayisi}&siparis_durum_id=${siparis_durum_id}`,
    true
  );
  const { maxHeight, tableBodyRef } = useMaxHeight(data, 85);
  const {
    sayfalama,
    sayfa_sayisi: toplam_sayfa_sayisi,
    siparisler,
  } = data?.hits?.data || {};
  useEffect(() => {
    getData(
      `${GET.TUM_SIPARISLER_DATA}?page=${sayfa_sayisi}&siparis_durum_id=${siparis_durum_id}`
    );
  }, [sayfa_sayisi, siparis_durum_id]);
  return (
    <Content>
      <Content.Wrapper>
        <Content.Title>Tüm Siparişler</Content.Title>
      </Content.Wrapper>
      <Content.Wrapper paddingTop="0">
        <Flex justify="space-between" align="flex-end">
          {/* <Controls.Input placeholder="Arama Alanı (Sipariş No)" /> */}
          <Pagination.Text>Mevcut Sayfa: {sayfa_sayisi}</Pagination.Text>
          <Form.Box css="margin-left:auto;">
            <Dropdown.Wrapper>
              <Dropdown.ToggleButton
                onClick={handleToggleClick}
                css="margin: 0;"
              >
                <Dropdown.Text>Filtre: {aktifItem}</Dropdown.Text>
                <ChevronIcon />
              </Dropdown.ToggleButton>
              {showDropdown && (
                <Dropdown>
                  <Dropdown.List>
                    <Dropdown.Item>
                      <Dropdown.Link
                        onClick={(e) =>
                          handleItemClick(e, "Teslimat Bekleyenler")
                        }
                        to={`${ROUTES.TUM_SIPARISLER}/1/${SIPARIS_DURUM.TESLIMAT_BEKLENIYOR}`}
                      >
                        Teslimat Bekleyenler
                      </Dropdown.Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Dropdown.Link
                        onClick={(e) => handleItemClick(e, "Teslim Edilenler")}
                        to={`${ROUTES.TUM_SIPARISLER}/1/${SIPARIS_DURUM.TESLIM_EDILDI}`}
                      >
                        Teslim Edilenler
                      </Dropdown.Link>
                    </Dropdown.Item>
                  </Dropdown.List>
                </Dropdown>
              )}
            </Dropdown.Wrapper>
          </Form.Box>
        </Flex>
      </Content.Wrapper>
      <Content.Wrapper paddingTop="0">
        <Table.Wrapper maxHeight="70vh">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.DataHead width="5%"></Table.DataHead>
                <Table.DataHead width="15%">Müşteri</Table.DataHead>
                <Table.DataHead width="15%">Teslimat Adresi</Table.DataHead>
                <Table.DataHead width="12%">Bayi / Araç</Table.DataHead>
                <Table.DataHead width="10%">Tutar / Adet</Table.DataHead>
                <Table.DataHead width="11%">Sipariş Tarihi</Table.DataHead>
                <Table.DataHead width="15%">Teslimat Türü</Table.DataHead>
                <Table.DataHead width="17%"></Table.DataHead>
              </Table.Row>
            </Table.Head>
            {siparisler?.length ? (
              <Table.Body ref={tableBodyRef} maxHeight={maxHeight}>
                <TumSiparislerTableContainer siparisler={siparisler} />
              </Table.Body>
            ) : null}
          </Table>
        </Table.Wrapper>
      </Content.Wrapper>
      <Pagination.Wrapper  css="margin-top:auto">
        <Flex justify="space-between">
          <Pagination.Text>Toplam {toplam_sayfa_sayisi} sayfa listeleniyor</Pagination.Text>
          <Pagination>
            <Pagination.LinkContainer>
              {sayfalama?.prev && (
                <Pagination.PageLink
                  to={`${ROUTES.TUM_SIPARISLER}/${sayfalama.prev}/${siparis_durum_id}`}
                  css="margin-right:60px;"
                >
                  <Pagination.IconContainer
                    direction="left"
                    css="margin-right:5px;"
                  >
                    <ChevronIcon />
                  </Pagination.IconContainer>
                  Önceki
                </Pagination.PageLink>
              )}
              {sayfalama?.next && (
                <Pagination.PageLink
                  to={`${ROUTES.TUM_SIPARISLER}/${sayfalama.next}/${siparis_durum_id}`}
                  noMargin
                >
                  Sonraki
                  <Pagination.IconContainer
                    direction="right"
                    css="margin-left:5px;"
                  >
                    <ChevronIcon />
                  </Pagination.IconContainer>
                </Pagination.PageLink>
              )}
            </Pagination.LinkContainer>
          </Pagination>
        </Flex>
      </Pagination.Wrapper>
    </Content>
  );
}

export default TumSiparisler;
