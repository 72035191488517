import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { POST } from '../constants/endpoints';
import usePostData from './usePostData';

const defaultWarn = {
  siparis_notu: '',
};

function useSiparisNotuDuzenlemeLogic(args) {
  const { siparis_notu, closeModal, getData } = args;
  const { siparis_id } = useParams();
  const [siparisNotu, setSiparisNotu] = useState(siparis_notu);
  const { resource, postData } = usePostData();
  const [warn, setWarn] = useState(defaultWarn);

  const inputlariKontrolEt = () => {
    const errObj = {};
    if (siparisNotu.length > 250) {
      errObj.siparis_notu = 'Sipariş notu maksimum 250 karakter olmalıdır';
    }
    if (Object.keys(errObj).length) {
      setWarn(errObj);
      return false;
    }
    setWarn(errObj);
    return true;
  };

  const succesfulSubmitHandler = () => {
    getData();
    closeModal();
  };

  const handleVazgecClick = () => {
    closeModal();
  };

  const handleSiparisNotuChange = (e) => {
    const { value } = e.target;
    setSiparisNotu(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (siparis_id && inputlariKontrolEt()) {
      postData(
        POST.SIPARIS_NOTU_DUZENLEME,
        { siparis_id, siparis_notu: siparisNotu },
        succesfulSubmitHandler
      );
    }
  };
  useEffect(() => {
    if (resource.error) {
      const { siparis_notu } = resource?.error?.response?.data || {};
      setWarn({ siparis_notu });
    }
  }, [resource.error]);

  const handlers = { handleVazgecClick, handleSubmit, handleSiparisNotuChange };

  return { handlers, siparisNotu, warn };
}

export default useSiparisNotuDuzenlemeLogic;
