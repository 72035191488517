import React from 'react';
import { SiparisDurum } from '../components';
import { durumaGoreIconGetir } from '../helpers';
import TimeAgo from 'react-timeago';
import 'styled-components/macro';
import { formatter } from '../constants/formatter';

function SiparisDurumContainer(props) {
  const {
    siparis_durum_id,
    siparis_durum_adi,
    siparis_tarihi,
    siparis_tarihi_format,
    teslimat_tarihi,
    teslimat_turu,
    noMargin,
    notification,
  } = props;

  return (
    <SiparisDurum.ContentWrapper>
      <SiparisDurum durum_id={siparis_durum_id} noMargin={noMargin}>
        <SiparisDurum.Wrapper css='margin-right:20px;'>
          <SiparisDurum.IconContainer durum_id={siparis_durum_id}>
            {durumaGoreIconGetir(siparis_durum_id)}
          </SiparisDurum.IconContainer>
        </SiparisDurum.Wrapper>
        <SiparisDurum.Wrapper>
          <SiparisDurum.Text durum_id={siparis_durum_id}>
            {siparis_durum_adi}
          </SiparisDurum.Text>
          <SiparisDurum.TextSmall>
            {siparis_tarihi ? (
              <TimeAgo
                date={siparis_tarihi}
                formatter={formatter}
                title={siparis_tarihi_format}
              />
            ) : (
              teslimat_turu
            )}
          </SiparisDurum.TextSmall>
          <SiparisDurum.Date>{teslimat_tarihi}</SiparisDurum.Date>
        </SiparisDurum.Wrapper>
      </SiparisDurum>
      {notification ? (
        <SiparisDurum.Notification>*{notification}</SiparisDurum.Notification>
      ) : null}
    </SiparisDurum.ContentWrapper>
  );
}

export default SiparisDurumContainer;
