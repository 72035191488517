import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { GET } from '../constants/endpoints';
import useFetchData from './useFetchData';
import * as ROUTES from '../constants/routes';

const defaultModalState = {
  showAdresModal: false,
  showSiparisModal1: false,
  showSiparisModal2: false,
  showSiparisModal3: false,
  showSiparisModal4: false,
  musteriBilgiModal: false,
  adresBilgiModal: false,
};

const defaultSiparisState = {
  adres_id: '',
  teslimat_tur_id: '',
  siparis_notu: '',
  teslimat_tarihi: '',
  urunler: [],
};

const defaultAdres = {
  adres_id: '',
  acik_adres: '',
};

function useMusteriProfilLogic(props) {
  const { closeDropdown } = props;
  const { musteri_id, telefon } = useParams();
  const { data, getData } = useFetchData(
    `${GET.MUSTERI_DETAY_DATA}?${telefon ? 'telefon' : 'musteri_id'}=${
      telefon || musteri_id
    }`,
    true
  );
  const [showDialog, setShowDialog] = useState(false);
  const history = useHistory();
  const [modal, setModal] = useState(defaultModalState);
  const [siparis, setSiparis] = useState(defaultSiparisState);
  const [adres, setAdres] = useState(defaultAdres);
  const { musteri, adresler, siparisler } = data?.hits?.data || {};

  const musteriModalCallback = () => {
    getData(`${GET.MUSTERI_DETAY_DATA}?musteri_id=${musteri.musteri_id}`);
    setModal(defaultModalState);
  };

  const handleAdresOlusturClick = () => {
    setModal({ ...defaultModalState, showAdresModal: true });
  };
  const handleSiparisOlusturClick = () => {
    setModal({ ...defaultModalState, showSiparisModal1: true });
  };
  const handleDialogOnayClick = () => {
    history.push(`${ROUTES.MUSTERILER_REDIRECTED}/redirect/1/3/${telefon}`);
  };

  const handleMusteriBilgileriYonetClick = () => {
    setModal({ ...defaultModalState, musteriBilgiModal: true });
    closeDropdown();
  };

  const handleAdresBilgileriYonetClick = (adres_id, acik_adres) => {
    setAdres({ adres_id, acik_adres });
    setModal({ ...defaultModalState, adresBilgiModal: true });
  };


  const handleDialogVazgecClick = () => {
    setShowDialog(false);
    history.push(`${ROUTES.MUSTERILER}/1/yeni`);
  };

  useEffect(() => {
    // Eğer ki telefon numarası ile bir müşteri profiline gidilmeye çalışıldıysa ve
    // böyle bir numara yoksa bu numara ile yeni bir müşteri eklemek için dialog açılacak.
    // Eğer var olmayan bir müşteri id'si kullanıldıysa müşteri listesine geri dönülecek.
    if (data.error) {
      if (telefon) {
        return setShowDialog(true);
      }
      history.push(`${ROUTES.MUSTERILER}/1/yeni`);
    }
  }, [data, setShowDialog, telefon, history]);
  const handlers = {
    handleAdresOlusturClick,
    handleSiparisOlusturClick,
    handleDialogOnayClick,
    handleDialogVazgecClick,
    handleMusteriBilgileriYonetClick,
    handleAdresBilgileriYonetClick,
  };
  const returnedObj = {
    handlers,
    modal,
    setModal,
    siparis,
    setSiparis,
    musteriModalCallback,
    musteri,
    musteri_id,
    adresler,
    siparisler,
    defaultModalState,
    defaultSiparisState,
    getData,
    data,
    showDialog,
    setShowDialog,
    telefon,
    adres
  };
  return returnedObj;
}

export default useMusteriProfilLogic;
