import styled from 'styled-components';
import { commonFontSet } from '../../../styles/mixins';
import { colors, fonts, fontSizes } from '../../../styles/variables';
import { Link as ReactRouterLink } from 'react-router-dom';

export const Container = styled.div`
  font-family: ${fonts.roboto};
  font-size: ${fontSizes.md};
`;

export const Title = styled.h3`
  color: ${colors.darkBlue};
  ${commonFontSet}
`;

export const TitleSmall = styled.h4`
  color: ${colors.darkGray};
  font-weight: 500;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  color: ${colors.gray3};
`;

export const Link = styled(ReactRouterLink)`
  color: ${colors.gray3};
  display: flex;
  flex-direction: column;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const Bold = styled.span`
  ${commonFontSet}
  color: ${colors.darkGray};
  font-family: ${fonts.roboto};
`;
