import { createGlobalStyle } from 'styled-components';
import { fonts, fontSizes, screens } from './variables';

export const GlobalStyles = createGlobalStyle`
*, *::before, *::after {
    box-sizing: border-box;
    margin:0;
    padding: 0;
} 
html, 
body {  
        background: #F3F5FB;
        color: #333;
        font-family: ${fonts.roboto};
        font-size: 16px;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        -webkit-overflow-scrolling: touch;

        @media ${screens.lg} {
            font-size: 13px;
        }
    }
   
    #root {
        min-height: 100vh;
        position: relative;
    }

    h1 {
        font-size: ${fontSizes.xl};
    }

    li {
        list-style: none;
    }

    button {
        appearance: none;
        border: none;
        cursor: pointer;
    }
    input {
        appearance: none;
        border: none;
    }

    a {
        text-decoration: none;
    }

    p {
        line-height: 1.4;
    }
    
    img {
        max-width: 100%;
    }
    [type="search"]::-webkit-search-cancel-button,
    [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
        appearance: none;
}

tbody {
    display: block;
    overflow: auto;
}

thead tr {
    display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

/* table thead, table tbody tr {
    display: table;
  width: 100%;
  table-layout: fixed;
}

table tbody {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
} */
`;
