import styled from 'styled-components/macro';
import { variantColorGetir } from '../../../styles/helpers';
import { commonFontSet } from '../../../styles/mixins';
import {
  colors,
  fontSizes,
  indexes,
  margin,
  radius,
  transitions,
} from '../../../styles/variables';

export const Button = styled.button`
  ${commonFontSet};
  background-color: ${colors.gray};
  border-radius: ${radius.roundedSM};
  color: ${colors.grayText};
  padding: 15px 0;
  text-align: center;
  transition: ${transitions.buttonBgHover};
  width: 200px;

  &:hover {
    background: ${colors.white_1_hover};
  }
`;

export const IconContainer = styled.div`
  border-radius: ${radius.roundedMD};
  color: white;
  display: flex;
  font-size: 2.4rem;
  padding: 19px;
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  & button:nth-child(2) {
    margin-left: ${margin.common};
  }
`;

export const Title = styled.h3`
  ${commonFontSet};
  font-size: ${fontSizes.lg};
`;

export const Container = styled.div`
  align-items: center;
  background-color: white;
  border-radius: ${radius.roundedLG};
  display: flex;
  flex-direction: column;
  left: 50%;
  padding: 30px;
  padding-top: 40px;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: ${indexes.poppedUI};
  width: 490px;

  & > div > ${Title} {
    color: ${({ variant }) => variantColorGetir(variant)};
  }
  & > div > ${IconContainer} {
    background-color: ${({ variant }) => variantColorGetir(variant)};
  }
  & > ${Box} ${Button}:last-child {
    background-color: ${({ variant }) => variantColorGetir(variant)};
    color: ${({ variant }) => (variant !== 'gray' ? 'white' : colors.grayText)};
  }
`;

export const Text = styled.p`
  ${commonFontSet};
  color: ${colors.grayText};
  margin-top: ${margin.common};
  margin-bottom: 1.5em;
  text-align: center;
`;
