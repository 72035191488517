import styled from "styled-components/macro";
import { commonFontSet } from "../../../styles/mixins";
import { colors, radius } from "../../../styles/variables";

export const Input = styled.input`
  ${commonFontSet}
  border-radius: ${radius.roundedSM};
  color: ${colors.grayText};
  padding: 15px 20px;
  width: 30%;

  &::placeholder {
    color: inherit;
    font: inherit;
  }
`;