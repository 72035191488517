import { useLayoutEffect, useRef, useState } from "react";

function useMaxHeight(dependency, minusHeight) {
  const [maxHeight, setMaxHeight] = useState(null);
  const tableBodyRef = useRef(null);

  useLayoutEffect(() => {
    if (tableBodyRef.current) {
      const { height, y } = tableBodyRef.current.getBoundingClientRect();
      height && setMaxHeight(window.innerHeight - y - minusHeight + "px");
    }
  }, [dependency, minusHeight, setMaxHeight]);

  return { tableBodyRef, maxHeight };
}

export default useMaxHeight;
