import React, { useState } from 'react';
import { MusteriContext } from '../context';

const defaultState = {
  siparis_id: null,
};

function MusteriProvider({ children, ...rest }) {
  const [currentMusteri, setCurrentMusteri] = useState(defaultState);

  return (
    <MusteriContext.Provider value={{currentMusteri,setCurrentMusteri}} {...rest}>
      {children}
    </MusteriContext.Provider>
  );
}

export default MusteriProvider;
