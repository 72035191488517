import React from 'react';
import { Table } from '../components';
import { v4 as uuidv4 } from 'uuid';
import * as ROUTES from '../constants/routes';

function MusteriTableContainer({ musteriler }) {
  return (
    <>
      {musteriler.map(
        ({
          musteri_id,
          musteri_ad_soyad,
          cep_telefonu,
          teslimat_adresi,
          kayit_tarihi,
          yeni_musteri,
        }) => (
          <Table.Row key={uuidv4()}>
            <Table.RowLink to={`${ROUTES.MUSTERI_PROFIL}/${musteri_id}`}>
              <Table.Data width='22%'>
                <Table.Text>{musteri_ad_soyad}</Table.Text>
              </Table.Data>
              <Table.Data width='21%'>
                <Table.Text>{cep_telefonu}</Table.Text>
              </Table.Data>
              <Table.Data width='21%'>
                <Table.Text>{kayit_tarihi}</Table.Text>
              </Table.Data>
              <Table.Data width='21%'>
                <Table.Text>{teslimat_adresi[0]}</Table.Text>
                {teslimat_adresi[1] && teslimat_adresi[2] && (
                  <Table.TextSmall>
                    {teslimat_adresi[1]}/{teslimat_adresi[2]}
                  </Table.TextSmall>
                )}
              </Table.Data>
              <Table.Data width='15%'>
                {yeni_musteri && <Table.Pill>Yeni Müşteri</Table.Pill>}
              </Table.Data>
            </Table.RowLink>
          </Table.Row>
        )
      )}
    </>
  );
}

export default MusteriTableContainer;
