export const colors = {
  blue: '#4FABEA',
  blueHover: '#3b93d0',
  blueLight: '#f1f8fd',
  blueTransparent: "#4fabea8a",
  darkBlue: '#0A0A36',
  darkPurple: '#424265',
  darkGray: '#434c60',
  gray: '#F9F9FB',
  gray2: '#EFEFF1',
  gray3: '#6B7488',
  gray4: "#D8DBE4",
  gray5: "#BBC4D8",
  grayBg: ' #EDEDED',
  grayText: '#9DA6BA',
  grayTextTransparent: "#e5e6ea",
  grayHover:"#ececec",
  grayHover2:"#f6f6f6",
  green: '#19CD92',
  greenLight: '#19cd921a',
  purple: '#BB6BD9',
  purpleLight: '#F8F0FB',
  red: '#FF2936',
  redLight: '#ff29361a',
  red2: '#EB5757',
  orange: '#FD8C27',
  whiteLight: '#ffffff0d',
};

export const fonts = {
  roboto: "'Roboto', sans-serif",
  poppins: "'Poppins', sans-serif",
};

export const fontSizes = {
  xl: '1.5rem', // 24px
  lg: '1.25rem', // 20px
  md: '1.063rem', // 17px
  sm: '0.938rem', // 15px
  xs: '0.875rem', // 14px
};

export const sizes = {
  menuWidth: '300px',
};

export const screens = {
  lg: '(max-width:80em)',
  lg2: '(max-width:85.375em)',
  xl: '(max-width:99em)',
};

export const radius = {
  roundedSM: '10px',
  roundedMD: '15px',
  roundedLG: '20px',
};

export const margin = {
  common: '20px',
  common2: '30px',
};

export const indexes = {
  backdrop: 30,
  poppedUI: 50,
};

export const transitions = {
  buttonBgHover: 'background-color 150ms',
};
