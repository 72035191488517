import { GET } from '../../../constants/endpoints';
import useFetchData from '../../useFetchData';

function useSiparisOlusturmaModal1Logic(params) {
  const { siparis, setSiparis, setModal, defaultModalState,musteri_id } = params;
  const { data } = useFetchData(
    `${GET.MUSTERI_ADRES_LISTE_DATA}?musteri_id=${musteri_id}`,
    true
  );
  const handleRadioChange = (e) => {
    setSiparis({ ...siparis, adres_id: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleVazgecClick = () => {
    setModal({ ...defaultModalState, showSiparisModal1: false });
  };
  const handleIlerleClick = () => {
    const { adresler } = data?.hits?.data || {};
    if (adresler?.length) {
      const varsayilanAdres = adresler.find((adres) =>
        Boolean(adres.varsayilan)
      );
      setSiparis({ ...siparis, adres_id: siparis.adres_id || varsayilanAdres.adres_id });
    }
    setModal({
      ...defaultModalState,
      showSiparisModal1: false,
      showSiparisModal2: true,
    });
  };

  const handlers = {
    handleRadioChange,
    handleSubmit,
    handleVazgecClick,
    handleIlerleClick,
  };
  return { handlers,data };
}

export default useSiparisOlusturmaModal1Logic;
