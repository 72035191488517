import React from 'react';
import { Input } from './styles/controls';

function Controls({children,...rest}) {
  return <div {...rest}>{children}</div>;
}

Controls.Input = function ControlsInput({ ...rest }) {
  return <Input {...rest} />;
};

export default Controls;
