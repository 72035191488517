import React from 'react';
import { Flex, Urun } from '../components';
import { colors } from '../styles/variables';

function SiparisOzet({ toplam_tutar, urun_sayisi, depozito_tutari, indirim_tutari }) {
  return (
    <Flex direction='column'>
      <Urun.TextContainer noMargin>
        <Urun.Text>Toplam Ürün</Urun.Text>
        <Urun.Text>{urun_sayisi} Adet</Urun.Text>
      </Urun.TextContainer>
      <Urun.TextContainer>
        <Urun.Text>Toplam Depozito Tutarı</Urun.Text>
        <Urun.Text>{depozito_tutari}</Urun.Text>
      </Urun.TextContainer>
      <Urun.TextContainer>
        <Urun.Text>Toplam İndirim Tutarı</Urun.Text>
        <Urun.Text>{indirim_tutari}</Urun.Text>
      </Urun.TextContainer>
      <Urun.TextContainer>
        <Urun.Text color={colors.darkBlue}>Toplam Tutar</Urun.Text>
        <Urun.Text color={colors.darkBlue}>{toplam_tutar}</Urun.Text>
      </Urun.TextContainer>
    </Flex>
  );
}

export default SiparisOzet;
