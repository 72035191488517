import React from 'react';
import { Backdrop, Form, Modal } from '../../components';
import { useMusteriBilgiModalLogic } from '../../hooks';
import 'styled-components/macro';

function MusteriBilgiModal(props) {
  const {
    defaultModalState,
    setModal,
    musteri_id,
    musteri_ad_soyad,
    musteriModalCallback,
  } = props;
  const { handlers, isim, warn } = useMusteriBilgiModalLogic({
    defaultModalState,
    setModal,
    musteri_id,
    musteriModalCallback,
    musteri_ad_soyad
  });
  const { handleSubmit, handleVazgecClick, handleIsımChange } = handlers;

  return (
    <>
      <Backdrop handler={handleVazgecClick} />
      <Modal>
        <Modal.Wrapper>
          <Modal.SectionTitle>Müşteri Bilgilerini Yönet</Modal.SectionTitle>
          <Modal.Text>
            Kişisel bilgiler ile ilgili düzenlemeleri aşağıdan yapabilirsiniz.
          </Modal.Text>
          <Modal.Form onSubmit={handleSubmit}>
            <Modal.FieldContainer>
              <Modal.SectionTitle>Kişisel Bilgiler</Modal.SectionTitle>
              <Modal.Fieldset>
                <div>
                  <Form.Label htmlFor='input-1'>Ad Soyad</Form.Label>
                  <Form.Input
                    onChange={handleIsımChange}
                    id='input-1'
                    name='input-1'
                    type='text'
                    placeholder={'Ad Soyad'}
                    value={isim}
                  />
                </div>
              </Modal.Fieldset>
              {warn.isim && <Form.Warn>{warn.isim}</Form.Warn>}
            </Modal.FieldContainer>
            <Modal.Box>
              <Modal.Button autoFocus onClick={handleVazgecClick}>
                Vazgeç
              </Modal.Button>
              <Modal.ButtonBlue>Değişiklikleri Kaydet</Modal.ButtonBlue>
            </Modal.Box>
          </Modal.Form>
        </Modal.Wrapper>
      </Modal>
    </>
  );
}

export default MusteriBilgiModal;
