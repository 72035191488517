import React from 'react';
import {
  Container,
  Title,
  TitleSmall,
  Text,
  Link,
  Bold,
} from './styles/address';

function Address({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

Address.Title = function AddressTitle({ children, ...rest }) {
  return <Title {...rest}>{children}</Title>;
};

Address.TitleSmall = function AddressTitleSmall({ children, ...rest }) {
  return <TitleSmall {...rest}>{children}</TitleSmall>;
};

Address.Text = function AddressText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

Address.Link = function AddressLink({ children, ...rest }) {
  return <Link {...rest}>{children}</Link>;
};

Address.Bold = function AddressBold({ children, ...rest }) {
  return <Bold {...rest}>{children}</Bold>;
};

export default Address;
