import React from 'react';
import { Dashboard, Flex } from '../components';
import { ChevronIcon, TrendingIcon } from '../components/icons';

function DashboardCardContainer({
  data,
  up = false,
  icon,
  title,
  placeholder,
  linkTo,
}) {
  return (
    <Dashboard.Card>
      <Dashboard.TopWrapper>
        <Dashboard.IconContainer>{icon}</Dashboard.IconContainer>
        <Dashboard.ContentWrapper>
          <Flex justify='space-between'>
            <Dashboard.Title>{title}</Dashboard.Title>
       {/*      <Dashboard.StatContainer up={up}>
              <Dashboard.StatText>{up ? '+' : '-'}%99</Dashboard.StatText>
              <Dashboard.TrendingIconContainer>
                <TrendingIcon />
              </Dashboard.TrendingIconContainer>
            </Dashboard.StatContainer> */}
          </Flex>
          <Flex>
            <Dashboard.Count>{data}</Dashboard.Count>
          </Flex>
          <Flex>
            <Dashboard.ButtonLink to={linkTo}>
              Tümünü Görüntüle <ChevronIcon />
            </Dashboard.ButtonLink>
          </Flex>
        </Dashboard.ContentWrapper>
      </Dashboard.TopWrapper>
{/*       <Dashboard.Searchbox placeholder={`Arama Alanı (${placeholder})`} /> */}
    </Dashboard.Card>
  );
}

export default DashboardCardContainer;
