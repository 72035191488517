import React from 'react';
import {
  Container,
  Wrapper,
  Inner,
  Title,
  Text,
  IconContainer,
} from './styles/section';

function Section({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

Section.Wrapper = function SectionWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Section.Inner = function SectionInner({ children, ...rest }) {
  return <Inner {...rest}>{children}</Inner>;
};

Section.Title = function SectionTitle({ children, ...rest }) {
  return <Title {...rest}>{children}</Title>;
};

Section.Text = function SectionText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

Section.IconContainer = function SectionIconContainer({ children, ...rest }) {
  return <IconContainer {...rest}>{children}</IconContainer>;
};

export default Section;
