import React from 'react';
import {
  Container,
  Wrapper,
  ContentWrapper,
  Text,
  TextSmall,
  Date,
  IconContainer,
  Notification,
} from './styles/siparis-durum';

function SiparisDurum({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

SiparisDurum.Text = function SiparisDurumText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

SiparisDurum.Wrapper = function SiparisDurumWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

SiparisDurum.ContentWrapper = function SiparisDurumContentWrapper({
  children,
  ...rest
}) {
  return <ContentWrapper {...rest}>{children}</ContentWrapper>;
};

SiparisDurum.TextSmall = function SiparisDurumTextSmall({ children, ...rest }) {
  return <TextSmall {...rest}>{children}</TextSmall>;
};

SiparisDurum.Date = function SiparisDurumDate({ children, ...rest }) {
  return <Date {...rest}>{children}</Date>;
};

SiparisDurum.IconContainer = function SiparisDurumIconContainer({
  children,
  ...rest
}) {
  return <IconContainer {...rest}>{children}</IconContainer>;
};

SiparisDurum.Notification = function SiparisDurumNotification({
  children,
  ...rest
}) {
  return <Notification {...rest}>{children}</Notification>;
};

export default SiparisDurum;
