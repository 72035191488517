import React from 'react';
import { Backdrop, Dropdown, Form, Modal } from '../../../components';
import { GET } from '../../../constants/endpoints';
import { useMusteriEklemeModal2Logic } from '../../../hooks';
import DropdownSearchbox from '../../dropdown-searchbox';

function MusteriEklemeModal2({ musteri_id, callback, vazgecCallback }) {
  const { handlers, displayedLists, warn, adresArama, adres } =
    useMusteriEklemeModal2Logic(musteri_id, callback);

  const {
    handleIlChange,
    handleIlceChange,
    handleMahalleChange,
    handleAdresAdiChange,
    handleAcikAdresChange,
    handleYolTarifiChange,
    handleSubmit,
    handleSearchboxFocus,
    handleIlItemClick,
    handleIlceItemClick,
    handleMahalleItemClick,
  } = handlers;

  const handleVazgecClick = () => vazgecCallback && vazgecCallback();
  const { displayedIlList, displayedIlceList, displayedMahalleList } =
    displayedLists;

  const {
    il_adi: il_adi_arama,
    ilce_adi: ilce_adi_arama,
    mahalle_adi: mahalle_adi_arama,
  } = adresArama;
  const { il_id, ilce_id, mahalle_id, adres_adi, acik_adres, yol_tarifi } =
    adres;
  const { il_adi, ilce_adi, mahalle_adi } = adres.adlar;
  return (
    <>
      <Backdrop handler={vazgecCallback} />
      <Modal>
        <Modal.Wrapper>
          <Modal.Form onSubmit={handleSubmit}>
            <Modal.FieldContainer>
              <Modal.SectionTitle>Adres Ekle</Modal.SectionTitle>
              <Modal.Text>
                Yeni adres eklemek için aşağıdaki bilgileri doldurunuz.
              </Modal.Text>
            </Modal.FieldContainer>
            <Modal.FieldContainer>
              <Modal.SectionTitle>Adres</Modal.SectionTitle>
              <Modal.Fieldset>
                <div>
                  <Form.Label htmlFor='il'>İl</Form.Label>
                  <DropdownSearchbox
                    autoFocus
                    handleChange={handleIlChange}
                    value={il_adi || il_adi_arama}
                    htmlFor='il'
                    placeholder='Lütfen İl Seçiniz'
                    focusHandler={() => handleSearchboxFocus(GET.IL_LISTE_DATA)}
                    list={displayedIlList}
                  >
                    {displayedIlList.map(({ il_id, il_adi }) => (
                      <Dropdown.Item key={il_id}>
                        <Dropdown.Button
                          onClick={(e) => handleIlItemClick(e, il_id, il_adi)}
                        >
                          {il_adi}
                        </Dropdown.Button>
                      </Dropdown.Item>
                    ))}
                  </DropdownSearchbox>
                  {warn.il_id && <Form.Warn>{warn.il_id}</Form.Warn>}
                </div>
                <div>
                  <Form.Label htmlFor='ilce'>İlçe</Form.Label>
                  <DropdownSearchbox
                    handleChange={handleIlceChange}
                    value={ilce_adi || ilce_adi_arama}
                    htmlFor='ilce'
                    placeholder='Lütfen İlçe Seçiniz'
                    disabled={!il_id}
                    focusHandler={() =>
                      handleSearchboxFocus(
                        `${GET.ILCE_LISTE_DATA}?il_id=${il_id}`
                      )
                    }
                    list={displayedIlceList}
                  >
                    {displayedIlceList.map(({ ilce_id, ilce_adi }) => (
                      <Dropdown.Item key={ilce_id}>
                        <Dropdown.Button
                          onClick={(e) =>
                            handleIlceItemClick(e, ilce_id, ilce_adi)
                          }
                        >
                          {ilce_adi}
                        </Dropdown.Button>
                      </Dropdown.Item>
                    ))}
                  </DropdownSearchbox>
                  {warn.ilce_id && <Form.Warn>{warn.ilce_id}</Form.Warn>}
                </div>
              </Modal.Fieldset>
            </Modal.FieldContainer>
            <Modal.FieldContainer>
              <Modal.Fieldset>
                <div>
                  <Form.Label htmlFor='mahalle'>Mahalle</Form.Label>
                  <DropdownSearchbox
                    handleChange={handleMahalleChange}
                    value={mahalle_adi || mahalle_adi_arama}
                    htmlFor='mahalle'
                    placeholder='Lütfen Mahalle Seçiniz'
                    list={displayedMahalleList}
                    disabled={!ilce_id}
                  >
                    {displayedMahalleList.map(
                      ({ mahalle_id, mahalle_adi, semt_id }) => (
                        <Dropdown.Item key={mahalle_id}>
                          <Dropdown.Button
                            onClick={(e) =>
                              handleMahalleItemClick(
                                e,
                                mahalle_id,
                                mahalle_adi,
                                semt_id
                              )
                            }
                          >
                            {mahalle_adi}
                          </Dropdown.Button>
                        </Dropdown.Item>
                      )
                    )}
                  </DropdownSearchbox>
                  {warn.mahalle_id && <Form.Warn>{warn.mahalle_id}</Form.Warn>}
                </div>
                <div>
                  <Form.Label htmlFor='input-1'>Adres Başlığı</Form.Label>
                  <Form.Input
                    type='text'
                    name='input-1'
                    id='input-1'
                    onChange={handleAdresAdiChange}
                    placeholder='Örn: Ev'
                    value={adres_adi}
                  />
                  {warn.adres_adi && <Form.Warn>{warn.adres_adi}</Form.Warn>}
                </div>
              </Modal.Fieldset>
            </Modal.FieldContainer>
            <Modal.FieldContainer>
              <div>
                <Form.Label htmlFor='input-2'>
                  Açık &#x41;&#x64;&#x72;&#x65;&#x73;
                </Form.Label>
                <Form.Input
                  type='text'
                  name='input-2'
                  id='input-2'
                  onChange={handleAcikAdresChange}
                  placeholder='Lütfen Açık &#x41;&#x64;&#x72;&#x65;&#x73; Giriniz'
                  value={acik_adres}
                />
                {warn.acik_adres && <Form.Warn>{warn.acik_adres}</Form.Warn>}
              </div>
            </Modal.FieldContainer>
            <Modal.FieldContainer>
              <div>
                <Form.Label htmlFor='input-3'>Yol Tarifi</Form.Label>
                <Form.Input
                  type='text'
                  id='input-3'
                  onChange={handleYolTarifiChange}
                  placeholder='Lütfen Yol Tarifi Giriniz'
                  value={yol_tarifi}
                />
              </div>
            </Modal.FieldContainer>
            <Modal.Box>
              <Modal.Button onClick={handleVazgecClick}>Vazgeç</Modal.Button>
              <Modal.ButtonBlue>Kaydet</Modal.ButtonBlue>
            </Modal.Box>
          </Modal.Form>
        </Modal.Wrapper>
      </Modal>
    </>
  );
}

export default MusteriEklemeModal2;
