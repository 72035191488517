import { useEffect, useState } from 'react';
import { POST } from '../../constants/endpoints';
import usePostData from '../usePostData';

const defaultWarn = {
  isim: '',
};

function useMusteriBilgiModalLogic(args) {
  const {
    setModal,
    defaultModalState,
    musteri_id,
    musteri_ad_soyad,
    musteriModalCallback,
  } = args;
  const [isim, setIsim] = useState(musteri_ad_soyad);
  const { resource, postData } = usePostData();
  const [warn, setWarn] = useState(defaultWarn);

  const handleVazgecClick = () => {
    setModal({ ...defaultModalState });
  };
  const handleIsımChange = (e) => {
    const { value } = e.target;
    setIsim(value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isim && musteri_id) {
      postData(
        POST.MUSTERI_ISIM_GUNCELLEME,
        { isim, musteri_id },
        musteriModalCallback
      );
    }
  };

  useEffect(() => {
    if (resource.error) {
      const { isim } = resource?.error?.response?.data || {};
      setWarn({ isim });
    }
  }, [resource.error]);

  const handlers = { handleVazgecClick, handleSubmit, handleIsımChange };
  return { handlers, isim, warn };
}

export default useMusteriBilgiModalLogic;
