import React from 'react';
import { Container, Dashed, Gray } from './styles/button';

function Button({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

export function ButtonDashed({ children, ...rest }) {
  return <Dashed {...rest}>{children}</Dashed>;
}

export function ButtonGray({ children, ...rest }) {
  return <Gray {...rest}>{children}</Gray>;
}

export default Button;
