import React from 'react';
import { Content, Flex, Footer, Img } from '../components';
import { GirisFormContainer } from '../containers';
import GirisImg from '../assets/img/giris.png';
import ArtiIkiLogo from '../assets/img/artiiki-logo.png';
import { ReactComponent as Decor } from '../assets/img/decor-giris.svg';
import 'styled-components/macro';

function Giris() {

  return (
    <Content
      css={`
        background-color: white;
        margin-left: 0;
      `}
    >
      <Flex justify='space-between' css='min-height:100vh;'>
        <Img.Wrapper css='flex-basis:60%; max-height:100vh;'>
          <Img src={GirisImg} />
          <Img.TextWrapper css='margin-top:20px;'>
            <Img.Title>Gümüş Su Müşteri Yönetim Sistemi</Img.Title>
            <Img.Text css='width:54ch;'>
              Müşteri siparişlerinin takibini kolay ve pratik bir şekilde
              yönetebilir, bu avantajdan faydanalabilirsin.
            </Img.Text>
            <Decor css='margin-top:30px;' />
          </Img.TextWrapper>
        </Img.Wrapper>
        <Flex css='min-height:100vh; flex-basis:40%;' direction='column'>
          <GirisFormContainer />
          <Footer>
            <Footer.Wrapper>
              <Footer.Link target='_blank' href='https://artiiki.com/'>
                <Footer.Img src={ArtiIkiLogo} alt='bursa web tasarım' />
              </Footer.Link>
            </Footer.Wrapper>
          </Footer>
        </Flex>
      </Flex>
    </Content>
  );
}

export default Giris;
