import React from 'react';
import { Backdrop, Form, Modal } from '../../components';
import { useAdresBilgiModalLogic } from '../../hooks';
import 'styled-components/macro';

function AdresBilgiModal(props) {
  const { adres_id,acik_adres, defaultModalState, setModal, musteriModalCallback, } = props;
  const { handlers, acikAdres, warn } = useAdresBilgiModalLogic({
    adres_id,
    acik_adres,
    defaultModalState,
    setModal,
    musteriModalCallback,
  });
  const { handleSubmit, handleVazgecClick, handleAcikAdresChange } = handlers;

  return (
    <>
      <Backdrop handler={handleVazgecClick} />
      <Modal>
        <Modal.Wrapper>
          <Modal.SectionTitle>Adres Bilgilerini Yönet</Modal.SectionTitle>
          <Modal.Text>
            Adresler ile ilgili düzenlemeleri aşağıdan yapabilirsiniz.
          </Modal.Text>
          <Modal.Form onSubmit={handleSubmit}>
            <Modal.FieldContainer>
              <Modal.SectionTitle>Adres</Modal.SectionTitle>
              <div css='width:100%; margin-top:20px;'>
                <Form.Label htmlFor='input-1'>Açık Adres</Form.Label>
                <Form.Input
                  onChange={handleAcikAdresChange}
                  id='input-1'
                  name='input-1'
                  type='text'
                  placeholder={'Açık Adres'}
                  value={acikAdres}
                />
              </div>
              {warn.acik_adres && <Form.Warn>{warn.acik_adres}</Form.Warn>}
            </Modal.FieldContainer>
            <Modal.Box>
              <Modal.Button autoFocus onClick={handleVazgecClick}>
                Vazgeç
              </Modal.Button>
              <Modal.ButtonBlue>Değişiklikleri Kaydet</Modal.ButtonBlue>
            </Modal.Box>
          </Modal.Form>
        </Modal.Wrapper>
      </Modal>
    </>
  );
}

export default AdresBilgiModal;
