import styled from 'styled-components/macro';
import {
  colors,
  fonts,
  fontSizes,
  margin,
  radius,
  screens,
} from '../../../styles/variables';

import { commonFontSet } from '../../../styles/mixins';

export const Container = styled.form`
  font-size: ${fontSizes.md};
  font-family: ${fonts.poppins};
  font-weight: 500;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Box = styled.div`
  display: flex;
`;

export const Title = styled.h2``;

export const Label = styled.label`
  ${commonFontSet}
  color: ${colors.grayText};
`;

export const Fieldset = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: ${margin.common};
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Text = styled.p`
  margin-top: 10px;
`;

export const IconContainer = styled.div`
  color: ${colors.grayText};
  display: flex;
  font-size: 1.5rem;
  padding: 0.3em;
  position: absolute;
  right: 15px;
  transform: translateY(-50%);
  top: calc(50% + 5px);
`;

export const TextSmall = styled.p``;

export const TextWrapper = styled.div``;

export const Warn = styled.p`
  color: ${colors.red};
  font-size: ${fontSizes.sm};
  margin-top: 10px;
`;

export const Button = styled.button``;

export const Input = styled.input`
  background-color: ${colors.gray};
  border-radius: ${radius.roundedSM};
  color: ${colors.darkBlue};
  font-family: ${fonts.roboto};
  font-size: ${fontSizes.md};
  font-weight: inherit;
  margin-top: 10px;
  padding: 15px 20px;
  outline: none;
  width: 100%;

  & + ${IconContainer} {
    color: ${({ variant }) =>
      variant === 'warn' ? colors.red : colors.grayText} !important;
  }

  &::placeholder {
    color: ${colors.grayText};
    font: inherit;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 2px ${colors.blue};
  }

  ${({ variant }) =>
    variant === 'warn' && `box-shadow: 0 0 0 2px ${colors.red} !important;`}

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled::placeholder {
    color: ${colors.grayTextTransparent}
  }

  &:disabled:active {
    box-shadow: none;
  }
`;
