import React from 'react';
import { Flex, Form, Button } from '../components';
import { ReactComponent as GumussuYazi } from '../assets/img/gumussu-yazisvg.svg';
import 'styled-components/macro';
import { Eye, EyeCross, WarnIcon } from '../components/icons';
import { useGirisFormLogic } from '../hooks';

function GirisContainer() {
  const {
    handleFormSubmit,
    handlePasswordChange,
    handleUsernameChange,
    handlePasswordToggleClick,
    username,
    password,
    showPassword,
    resource,
    warn,
  } = useGirisFormLogic();

  return (
    <Flex css='margin:auto'>
      <Flex
        align='flex-start'
        css='margin:auto'
        direction='column'
        width='auto'
      >
        <Flex>
          <Form.TextWrapper>
            <GumussuYazi />
          </Form.TextWrapper>
        </Flex>
        <Form css='width:440px;' onSubmit={handleFormSubmit}>
          <Form.Fieldset>
            <Form.Label for='username'>Kullanıcı Adı</Form.Label>
            <Form.InputWrapper>
              <Form.Input
                id='username'
                placeholder='Kullanıcı adınızı giriniz'
                value={username}
                onChange={handleUsernameChange}
                variant={warn.username && 'warn'}
              />
              {warn.username && (
                <Form.IconContainer>
                  <WarnIcon />
                </Form.IconContainer>
              )}
            </Form.InputWrapper>
          </Form.Fieldset>
          {warn.username && <Form.Warn>{warn.username}</Form.Warn>}
          <Form.Fieldset>
            <Form.Label for='sifre'>Şifre</Form.Label>
            <Form.InputWrapper>
              <Form.Input
                id='sifre'
                placeholder='Parolanızı giriniz'
                type={showPassword ? 'number' : 'password'}
                onChange={handlePasswordChange}
                value={password}
                variant={warn.password && 'warn'}
              />
              <Form.IconContainer onClick={handlePasswordToggleClick}>
                {showPassword ? <EyeCross /> : <Eye />}
              </Form.IconContainer>
            </Form.InputWrapper>
          </Form.Fieldset>
          {warn.password && <Form.Warn>{warn.password}</Form.Warn>}
          <Button disabled={resource.isLoading} width="100%">Giriş Yap</Button>
        </Form>
      </Flex>
    </Flex>
  );
}

export default GirisContainer;
