import { useCallback, useEffect, useState } from 'react';
import { TESLIMAT_TUR } from '../../../constants/teslimat-tur';

const defaultTakvim = {
  tarih: '',
  saat: '',
};
const defaultWarn = {
  teslimat_tur_id: '',
  tarih: '',
  saat: '',
};

function useSiparisOlusturmaModal3Logic(params) {
  const [takvim, setTakvim] = useState(defaultTakvim);
  const [warn, setWarn] = useState(defaultWarn);
  const [siparisNotu, setSiparisNotu] = useState('');
  const { siparis, setSiparis, defaultModalState, setModal } = params;

  const inputlariKontrolEt = useCallback(() => {
    const { tarih, saat } = takvim;
    const { teslimat_tur_id } = siparis;
    const errObj = {};
    if (!teslimat_tur_id)
      errObj.teslimat_tur_id = 'Lütfen teslimat türünü seçiniz.';
    if (!tarih && teslimat_tur_id === TESLIMAT_TUR.ILERI_TARIH)
      errObj.tarih = 'Lütfen bir tarih seçiniz';
    if (!saat && teslimat_tur_id === TESLIMAT_TUR.ILERI_TARIH)
      errObj.saat = 'Lütfen bir saat seçiniz';
    if (siparisNotu.length > 250) {
      errObj.siparis_notu = 'Sipariş notu maksimum 250 karakter olmalıdır';
    }
    if (Object.keys(errObj).length) {
      setWarn(errObj);
      return false;
    }
    setWarn(errObj);
    return true;
  }, [siparis, siparisNotu.length, takvim]);

  const handleSiparisNotuChange = (e) => {
    setSiparisNotu(e.target.value);
  };

  const handleRadioChange = (e) => {
    setWarn(defaultWarn);
    setSiparis({ ...siparis, teslimat_tur_id: e.target.value });
  };
  const handleGeriDonClick = () => {
    setModal({ ...defaultModalState, showSiparisModal2: true });
  };
  const handleIlerleClick = () => {
    if (inputlariKontrolEt()) {
      setSiparis({
        ...siparis,
        siparis_notu: siparisNotu,
        teslimat_tarihi: `${takvim.tarih} ${takvim.saat}`,
      });
      setModal({ ...defaultModalState, showSiparisModal4: true });
    }
  };

  const handleTarihChange = (e) => {
    const { value } = e.target;
    setTakvim({ ...takvim, tarih: value });
  };
  const handleSaatClick = (saat) => {
    setTakvim({ ...takvim, saat });
  };

  useEffect(() => {
    if (!siparis.teslimat_tur_id) {
      setSiparis({ ...siparis, teslimat_tur_id: TESLIMAT_TUR.HEMEN });
    }
  }, [setSiparis, siparis]);
  const handlers = {
    handleRadioChange,
    handleTarihChange,
    handleIlerleClick,
    handleGeriDonClick,
    handleSaatClick,
    handleSiparisNotuChange,
  };

  return { handlers, warn, siparisNotu };
}

export default useSiparisOlusturmaModal3Logic;
