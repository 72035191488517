import { useState } from 'react';
import useWindowKey from './useWindowKey';

function useDropdownLogic(defaultAktifItem) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [aktifItem, setAktifItem] = useState(defaultAktifItem);
  const closeDropdown = () => setShowDropdown(false);
  useWindowKey({
    keys: ['Escape'],
    handlers: [closeDropdown],
    condition: showDropdown,
  });

  const handleItemClick = (e, itemText) => {
    e.stopPropagation();
    closeDropdown();
    setAktifItem(itemText);
  };

  const handleToggleClick = (e) => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  };
  return {
    showDropdown,
    closeDropdown,
    aktifItem,
    handleItemClick,
    handleToggleClick,
  };
}

export default useDropdownLogic;
