import React, { useRef, useState } from 'react';
import 'styled-components/macro';
import { Dropdown, Flex, Form } from '../components';
import { SearchIcon } from '../components/icons';
import { useWindowKey } from '../hooks';

function DropdownSearchbox(props) {
  const {
    children,
    placeholder = '',
    handleChange,
    handleKeyDown = () => {},
    focusHandler,
    value,
    list,
    inputCSS,
    autoFocus,
    disabled,
    ...rest
  } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const dropDownRef = useRef();
  useWindowKey({
    keys: ['Escape'],
    handlers: [() => setShowDropdown(false)],
    condition: showDropdown,
  });
  const handleFocus = () => {
    setShowDropdown(true);
    focusHandler && focusHandler();
  };

  const handleBlur = (e) => {
    if (dropDownRef?.current?.contains(e.relatedTarget)) return;
    setTimeout(() => setShowDropdown(false), 100);
    // !!! Dropdown kapatmazdan önce itemBtnClick'in çalışması için timeout eklendi.
  };

  const handleListClick = (e) => {
    e.stopPropagation();
    setShowDropdown(false);
  };
  // !!! list.length yerine başka şekilde kontrol et.
  return (
    <Flex ref={dropDownRef} css='margin-top:10px;' {...rest}>
      <Dropdown.Wrapper css='width:100%;'>
        <Form.InputWrapper>
          <Form.Input
            autoFocus={autoFocus}
            type='search'
            onChange={handleChange}
            value={value}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            autoComplete='new-password'
            disabled={disabled}
            css={
              `
              margin-top: 0;

              &:focus + div {
                display: none;
              }

              
            ` + inputCSS
            }
          />
          {/*  {!value && (
            <Form.IconContainer css='right:initial; left:0; top:50%;'>
              <SearchIcon />
            </Form.IconContainer>
          )} */}
        </Form.InputWrapper>
        {showDropdown && list?.length ? (
          <Dropdown css='width:100%'>
            <Dropdown.List
              css='max-height:300px; overflow-x:hidden; overflow-y:auto;'
              onClick={handleListClick}
            >
              {children}
            </Dropdown.List>
          </Dropdown>
        ) : null}
      </Dropdown.Wrapper>
    </Flex>
  );
}

export default DropdownSearchbox;
