import { useCallback, useEffect, useState } from 'react';
import { GET } from '../../../constants/endpoints';
import useFetchData from '../../useFetchData';
import useUrunLogic from '../../useUrunLogic';

const defaultWarn = { depozito: '', genel: '' };
function useSiparisOlusturmaModal2Logic(params) {
  const {
    setModal,
    getUrun,
    defaultModalState,
    defaultSiparisState,
    siparis,
    setSiparis,
  } = params;
  const { data } = useFetchData(
    `${GET.SIPARIS_URUN_LISTE_DATA}?adres_id=${siparis.adres_id}`,
    true
  );
  const { urunler = [] } = data?.hits?.data || {};
  const { urunState, increaseAmount, decreaseAmount, changeDepozito, init } =
    useUrunLogic(urunler);
  const [warn, setWarn] = useState(defaultWarn);

  const inputlariKontrolEt = () => {
    setWarn(defaultWarn);
    const warnObj = {};

    if (
      urunState.some(
        (urun) =>
          urun.depozito_urunler.length &&
          urun.adet >= 1 &&
          urun.depozito !== 'true' &&
          urun.depozito !== 'false'
      )
    ) {
      warnObj.depozito = 'Lütfen depozito seçiniz';
    }

    if (warnObj.depozito) {
      setWarn(warnObj);
      return false;
    }
    return true;
  };

  const responseErrorKontrolEt = useCallback(() => {
    const { error } = data?.error?.response?.data || {};
    const warnObj = { ...defaultWarn };
    if (error) warnObj.genel = error;
    if (warnObj.genel) setWarn(warnObj);
  }, [data]);

  const handleVazgecClick = () => {
    setModal({ ...defaultModalState, showSiparisModal2: false });
    setSiparis(defaultSiparisState);
  };

  const handleIlerleClick = () => {
    if (inputlariKontrolEt()) {
      getUrun(urunState);
      setModal({ ...defaultModalState, showSiparisModal3: true });
    }
  };

  useEffect(() => {
    if (urunler?.length) {
      init(urunler.map((urun) => ({ ...urun, adet: 0 })));
    }
  }, [urunler]);

  useEffect(() => {

    if (data.error) {
      responseErrorKontrolEt();
    }
  }, [data, responseErrorKontrolEt]);

  const handlers = { handleVazgecClick, handleIlerleClick };
  const urunLogic = {
    urunState,
    increaseAmount,
    decreaseAmount,
    changeDepozito,
  };
  return { handlers, urunLogic, urunler, warn };
}

export default useSiparisOlusturmaModal2Logic;
