import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { LOCAL_KEYS } from '../constants/localKeys';
import { getItem } from '../helpers/manageLocalStorage';

const defaultState = {
  hits: null,
  error: null,
  isLoading: false,
};

let request = null;

function useFetchData(defaultUrl, auto = false) {
  const [data, setData] = useState(defaultState);
  const [isMount, setIsMount] = useState(false);

  const getData = useCallback(
    (url = defaultUrl) => {
      request = axios.CancelToken.source();
      setData({ ...defaultState, isLoading: true });
      axios(
        {
          method: 'GET',
          url,
          headers: {
            Authorization: `Bearer ${getItem(LOCAL_KEYS.TOKEN)}`,
          },
        },
        {
          cancelToken: request.token,
        }
      )
        .then((resp) => {
          setData({ ...data, isLoading: false, hits: resp });
        })
        .catch((error) => setData({ ...data, isLoading: false, error }));
    },
    [data, defaultUrl]
  );
  useEffect(() => {
    setIsMount(true);
    if (auto && !isMount) getData();
    return () => request.cancel();
  }, [auto, getData, isMount]);

  return { getData, data };
}

export default useFetchData;
