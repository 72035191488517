import styled from 'styled-components/macro';
import { colors, fonts, fontSizes, margin } from '../../../styles/variables';

export const Container = styled.img`
  margin: auto;
  flex-shrink: 0;
`;

export const Wrapper = styled.div`
  background-color: ${colors.darkBlue};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 30px 45px 60px 45px;
`;

export const TextWrapper = styled.div`
  font-family: ${fonts.poppins};
  text-align: center;
`;

export const Title = styled.h1`
  color: white;
  font-size: ${fontSizes.xl};
  font-weight: 600;
`;

export const Text = styled.p`
  color: ${colors.grayText};
  font-size: ${fontSizes.lg};
  margin: auto;
  margin-top: ${margin.common};
`;

export const DecorContainer = styled.div``;

export const Decor = styled.div``;
