import React from "react";
import {
  Container,
  Wrapper,
  Head,
  Body,
  Data,
  DataHead,
  Row,
  RowLink,
  Link,
  Text,
  TextSmall,
  Price,
  Order,
  OrderSituation,
  OrderSituationIconContainer,
  Pill,
  IconContainer,
} from "./styles/table";

function Table({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}

Table.Wrapper = function TableWrapper({ children, ...rest }) {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Table.Head = function TableHead({ children, ...rest }) {
  return <Head {...rest}>{children}</Head>;
};

Table.Body = React.forwardRef(({ children, ...rest }, ref) => {
  return <Body ref={ref} {...rest}>{children}</Body>;
});

Table.Data = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <Data ref={ref} {...rest}>
      {children}
    </Data>
  );
});

Table.DataHead = function TableDataHead({ children, ...rest }) {
  return <DataHead {...rest}>{children}</DataHead>;
};

Table.Row = function TableRow({ children, ...rest }) {
  return <Row {...rest}>{children}</Row>;
};

Table.RowLink = function TableRowLink({ children, ...rest }) {
  return <RowLink {...rest}>{children}</RowLink>;
};

Table.Text = function TableText({ children, ...rest }) {
  return <Text {...rest}>{children}</Text>;
};

Table.TextSmall = function TableTextSmall({ children, ...rest }) {
  return <TextSmall {...rest}>{children}</TextSmall>;
};

Table.Price = function TablePrice({ children, ...rest }) {
  return <Price {...rest}>{children}</Price>;
};

Table.Link = function TableLink({ children, ...rest }) {
  return <Link {...rest}>{children}</Link>;
};

Table.Order = function TableOrder({ children, ...rest }) {
  return <Order {...rest}>{children}</Order>;
};

Table.OrderSituation = function TableOrderSituation({ children, ...rest }) {
  return <OrderSituation {...rest}>{children}</OrderSituation>;
};

Table.OrderSituationIconContainer = function TableOrderSituationIconContainer({
  children,
  ...rest
}) {
  return (
    <OrderSituationIconContainer {...rest}>
      {children}
    </OrderSituationIconContainer>
  );
};

Table.Pill = function TablePill({ children, ...rest }) {
  return <Pill {...rest}>{children}</Pill>;
};

Table.IconContainer = function TableIconContainer({ children, ...rest }) {
  return <IconContainer {...rest}>{children}</IconContainer>;
};

export default Table;
