import React from 'react';
import { Backdrop, Modal } from '../../components';
import { SIPARIS_DURUM } from '../../constants/siparis-durum';
import { useTeslimatDurumModalLogic } from '../../hooks';

function TeslimatDurumModalContainer({ getData, closeModal, durumlar }) {
  const {
    handleVazgecClick,
    handleRadioChange,
    handleTeslimEdilememeChange,
    handleSubmit,
    teslimatDurumu,
    warn,
    resource,
  } = useTeslimatDurumModalLogic(getData, closeModal);

  const { teslim_edilememe_durumlari } = durumlar || {};
  return (
    <>
      <Backdrop handler={closeModal} />
      <Modal>
        <Modal.Wrapper>
          <Modal.Title>Sipariş Detay</Modal.Title>
          <Modal.Form onSubmit={handleSubmit}>
            <Modal.FieldContainer>
              <Modal.SectionTitle>Teslimat Durumu</Modal.SectionTitle>
              <Modal.Text>
                Seçim yaparak teslimat durumunu belirleyebilirsiniz
              </Modal.Text>
              <Modal.Fieldset onChange={handleRadioChange}>
                <Modal.RadioLabel>
                  <Modal.Radio
                    name='teslimat_durumu'
                    value={SIPARIS_DURUM.TESLIM_EDILDI}
                  />
                  Teslim Edildi
                </Modal.RadioLabel>
                <Modal.RadioLabel>
                  <Modal.Radio
                    name='teslimat_durumu'
                    value={SIPARIS_DURUM.TESLIM_EDILEMEDI}
                  />
                  Teslim Edilemedi
                </Modal.RadioLabel>
              </Modal.Fieldset>
            </Modal.FieldContainer>
            {warn.teslimatDurumu ? (
              <Modal.Warn>{warn.teslimatDurumu}</Modal.Warn>
            ) : null}
            {teslimatDurumu.teslimat_durum_id ===
              SIPARIS_DURUM.TESLIM_EDILEMEDI && (
              <>
                <Modal.FieldContainer>
                  <Modal.SectionTitle>
                    Teslim Edilememe Durumu
                  </Modal.SectionTitle>
                  <Modal.Fieldset onChange={handleTeslimEdilememeChange}>
                    {teslim_edilememe_durumlari?.map(
                      ({
                        teslim_edilememe_durum_id,
                        teslim_edilememe_durumu,
                      }) => (
                        <Modal.RadioLabel key={teslim_edilememe_durum_id}>
                          <Modal.Radio
                            name='teslim_edilememe_durumu'
                            value={teslim_edilememe_durum_id}
                          />
                          {teslim_edilememe_durumu}
                        </Modal.RadioLabel>
                      )
                    )}
                  </Modal.Fieldset>
                </Modal.FieldContainer>
                {warn.teslimEdilememeDurumu ? (
                  <Modal.Warn>{warn.teslimEdilememeDurumu}</Modal.Warn>
                ) : null}
              </>
            )}
            <Modal.Box>
              <Modal.Button onClick={handleVazgecClick}>Vazgeç</Modal.Button>
              <Modal.ButtonBlue disabled={resource.isLoading}>
                Değişiklikleri Kaydet
              </Modal.ButtonBlue>
            </Modal.Box>
          </Modal.Form>
        </Modal.Wrapper>
      </Modal>
    </>
  );
}

export default TeslimatDurumModalContainer;
