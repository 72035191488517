import styled from 'styled-components/macro';
import { commonFontSet } from '../../../styles/mixins';
import {
  colors,
  fonts,
  fontSizes,
  indexes,
  margin,
  radius,
  transitions,
} from '../../../styles/variables';

export const Container = styled.div`
  ${commonFontSet}
  align-items: center;
  background-color: white;
  border-radius: ${radius.roundedLG};
  display: flex;
  flex-direction: column;
  left: calc(50% + 150px);
  max-width: 700px;
  padding: 30px;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: ${indexes.poppedUI};
`;

export const Wrapper = styled.div``;

export const Title = styled.h2`
  color: ${colors.blue};
  font-size: ${fontSizes.md};
`;

export const SectionTitle = styled.h3`
  color: ${colors.darkBlue};
  font-size: ${fontSizes.lg};
`;

export const Text = styled.p`
  color: ${colors.grayText};
`;

export const FormText = styled.p``;

export const Box = styled.div`
  display: flex;
  margin-top: ${margin.common2};
  & > button:nth-child(2) {
    margin-left: ${margin.common};
  }
`;

export const Form = styled.form``;

export const Fieldset = styled.fieldset`
  border: none;
  display: grid;
  gap: ${margin.common};
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: ${margin.common};
  /*   border: 2px solid ${colors.green};
  border-radius: ${radius.roundedSM}; */
`;

export const FieldContainer = styled.div`
  margin-top: ${margin.common};
  width:100%;
`;

export const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 15px;
`;

export const RadioLabel = styled(Label)`
  background-color: ${colors.gray};
  border: 2px solid ${colors.gray};
  border-radius: ${radius.roundedSM};
  color: ${colors.darkBlue};
`;

export const Radio = styled.input`
  border: 2px solid ${colors.gray4};
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 20px;
  margin-right: 15px;
  width: 20px;

  &::before {
    background-color: ${colors.gray4};
    border-radius: 50%;
    content: ' ';
    display: block;
    height: 10px;
    margin: auto;
    width: 10px;
  }

  &:checked {
    border-color: ${colors.green};
    &::before {
      background-color: ${colors.green};
    }
  }
`;

export const Button = styled.button`
  ${commonFontSet}
  background-color: ${colors.gray};
  border-radius: ${radius.roundedSM};
  color: ${colors.grayText};
  flex-basis: 100%;
  padding: 15px;
  transition: ${transitions.buttonBgHover};
  outline: none;

  &:hover,
  &:focus {
    background-color: ${colors.grayHover};
  }
`;

export const ButtonBlue = styled(Button)`
  background-color: ${colors.blue};
  color: white;

  &:disabled {
    background-color: ${colors.blueTransparent};
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    background-color: ${colors.blueHover};
  }
`;

export const Warn = styled.div`
  align-items: center;
  background-color: ${colors.redLight};
  border-radius: ${radius.roundedSM};
  color: ${colors.red};
  display: flex;
  font-family: ${fonts.roboto};
  font-weight: 400;
  margin-top: ${margin.common};
  padding: 22px;
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  font-size: ${fontSizes.xl};
`;

export const DisplayText = styled.p`
  margin-right: auto;
`;
export const Notification = styled.div``;
