import React from 'react';
import 'styled-components/macro';
import { Backdrop, Dropdown, Flex, Form, Modal } from '../../../components';
import { TESLIMAT_TUR } from '../../../constants/teslimat-tur';
import {
  useDropdownLogic,
  useSiparisOlusturmaModal3Logic,
} from '../../../hooks';
import { margin } from '../../../styles/variables';
const saatler = [
  '08:00',
  '08:30',
  '09:00',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
];

function SiparisOlusturmaModalContainer3(props) {
  const { siparis, setSiparis, defaultModalState, setModal } = props;
  const { handleToggleClick, aktifItem, showDropdown, handleItemClick } =
    useDropdownLogic('08:00');
  const { handlers, warn, siparisNotu } = useSiparisOlusturmaModal3Logic({
    siparis,
    setSiparis,
    defaultModalState,
    setModal,
  });

  const {
    handleRadioChange,
    handleTarihChange,
    handleSiparisNotuChange,
    handleIlerleClick,
    handleGeriDonClick,
    handleSaatClick,
  } = handlers;

  return (
    <>
      <Backdrop handler={() => setModal(defaultModalState)} />
      <Modal>
        <Modal.Wrapper>
          <Modal.Title>3 / 4</Modal.Title>
          <Flex align='flex-start' direction='column'>
            <Modal.FieldContainer>
              <Modal.SectionTitle>Sipariş Oluştur</Modal.SectionTitle>
              <Modal.Text>
                İlerleyebilmek için teslimat tarihi ve saatini belirleyiniz.
              </Modal.Text>
              <Modal.SectionTitle style={{ marginTop: margin.common }}>
                Teslimat Tarihi {'&'} Saati
              </Modal.SectionTitle>
              <Modal.Fieldset onChange={handleRadioChange}>
                <Modal.RadioLabel>
                  <Modal.Radio
                    name='teslimat_tur'
                    defaultChecked
                    value={TESLIMAT_TUR.HEMEN}
                  />
                  Hemen (120 dk)
                </Modal.RadioLabel>
                <Modal.RadioLabel>
                  <Modal.Radio
                    name='teslimat_tur'
                    value={TESLIMAT_TUR.ILERI_TARIH}
                  />
                  İleri Tarihli Teslimat
                </Modal.RadioLabel>
              </Modal.Fieldset>
            </Modal.FieldContainer>
            {warn.teslimat_tur_id && (
              <Modal.Warn>{warn.teslimat_tur_id}</Modal.Warn>
            )}
            {+siparis.teslimat_tur_id === +TESLIMAT_TUR.ILERI_TARIH && (
              <Modal.FieldContainer style={{ width: '100%' }}>
                <Modal.Fieldset
                  onChange={handleTarihChange}
                  style={{ width: '100%' }}
                >
                  <Flex align='flex-start' direction='column'>
                    <Form.Label>Tarih Seçimi</Form.Label>
                    <input type='date' />
                  </Flex>
                  <Flex align='flex-start' direction='column'>
                    <Form.Label>Saat Seçimi</Form.Label>
                    <Dropdown.Wrapper style={{ width: '100%' }}>
                      <Dropdown.ToggleButton
                        onClick={handleToggleClick}
                        style={{ width: '100%', minWidth: 'initial' }}
                      >
                        <Dropdown.Text>{aktifItem}</Dropdown.Text>
                      </Dropdown.ToggleButton>
                      {showDropdown && (
                        <Dropdown>
                          <Dropdown.List
                            style={{
                              maxHeight: '230px',
                              overflow: 'hidden auto',
                            }}
                          >
                            {saatler.map((saat) => (
                              <Dropdown.Item key={saat}>
                                <Dropdown.Button
                                  onClick={(e) => {
                                    handleItemClick(e, saat);
                                    handleSaatClick(saat);
                                  }}
                                >
                                  {saat}
                                </Dropdown.Button>
                              </Dropdown.Item>
                            ))}
                          </Dropdown.List>
                        </Dropdown>
                      )}
                    </Dropdown.Wrapper>
                  </Flex>
                </Modal.Fieldset>
              </Modal.FieldContainer>
            )}
            <Modal.FieldContainer>
              <Form.Fieldset>
                <Form.Label htmlFor='input-1'>Sipariş Notu</Form.Label>
                <Form.Input
                  id='input-1'
                  type='text'
                  placeholder='Sipariş notunu buraya yazabilirsiniz'
                  value={siparisNotu}
                  onChange={handleSiparisNotuChange}
                />
              </Form.Fieldset>
            </Modal.FieldContainer>
            {warn.siparis_notu && <Modal.Warn>{warn.siparis_notu}</Modal.Warn>}
            <Modal.Box style={{ width: '100%' }}>
              <Modal.Button onClick={handleGeriDonClick}>Geri Dön</Modal.Button>
              <Modal.ButtonBlue onClick={handleIlerleClick}>
                İlerle
              </Modal.ButtonBlue>
            </Modal.Box>
          </Flex>
        </Modal.Wrapper>
      </Modal>
    </>
  );
}

export default SiparisOlusturmaModalContainer3;
