import { useEffect, useState } from 'react';
import { POST } from '../../constants/endpoints';
import usePostData from '../usePostData';

const defaultWarn = {
  acik_adres: '',
};

function useAdresBilgiModalLogic(args) {
  const { adres_id, acik_adres,setModal, defaultModalState, musteriModalCallback } = args;
  const [acikAdres, setAcikAdres] = useState(acik_adres);
  const { resource, postData } = usePostData();
  const [warn, setWarn] = useState(defaultWarn);

  const handleVazgecClick = () => {
    setModal({ ...defaultModalState });
  };
  const handleAcikAdresChange = (e) => {
    const { value } = e.target;
    setAcikAdres(value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (adres_id) {
      postData(
        POST.MUSTERI_ADRES_DUZENLEME,
        { adres_id, acik_adres: acikAdres },
        musteriModalCallback
      );
    }
  };

  useEffect(() => {
    if (resource.error) {
      const { acik_adres } = resource?.error?.response?.data || {};
      setWarn({ acik_adres });
    }
  }, [resource.error]);

  const handlers = { handleVazgecClick, handleSubmit, handleAcikAdresChange };
  return { handlers, acikAdres, warn };
}

export default useAdresBilgiModalLogic;
