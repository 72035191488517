import React from 'react';
import 'styled-components/macro';
import { Content, Dashboard, Flex, Table } from '../components';
import { PinIcon, ShoppingBagIcon, UserIcon } from '../components/icons';
import { GET } from '../constants/endpoints';
import { DashboardCardContainer, DashboardTableContainer } from '../containers';
import { useFetchData, useMaxHeight } from '../hooks';
import * as ROUTES from '../constants/routes';
import { SIPARIS_DURUM } from '../constants/siparis-durum';

function Home() {
  const { data } = useFetchData(GET.DASHBOARD_DATA, true);
  const { maxHeight, tableBodyRef } = useMaxHeight(data, 40);

  return (
    <Content>
      <Content.Wrapper>
        <Content.Title>
          Merhaba, <Content.Slim> Hoşgeldin</Content.Slim>
        </Content.Title>
        <Dashboard>
          <Dashboard.Wrapper>
            <DashboardCardContainer
              data={data?.hits?.data.toplamSiparisSayisi}
              icon={<ShoppingBagIcon />}
              title='Bekleyen Siparişler'
              placeholder='Sipariş No'
              linkTo={`${ROUTES.TUM_SIPARISLER}/1/${SIPARIS_DURUM.TESLIMAT_BEKLENIYOR}`}
            />
            <DashboardCardContainer
              data={data?.hits?.data.toplamMusteriSayisi}
              icon={<UserIcon />}
              title='Müşteri'
              placeholder='Ad Soyad'
              linkTo={`${ROUTES.MUSTERILER}/1/yeni`}
              up
            />
            <DashboardCardContainer
              data={data?.hits?.data.toplamBayiSayisi}
              icon={<PinIcon />}
              title='Bayi'
              placeholder='Sipariş No'
            />
          </Dashboard.Wrapper>
        </Dashboard>
      </Content.Wrapper>
      <Content.Wrapper paddingTop='24px'>
        <Flex justify='space-between;'>
          <Content.Title>Teslimat Edilmesi Gereken Siparişler</Content.Title>
          <Table.Link
            to={`${ROUTES.TUM_SIPARISLER}/1/${SIPARIS_DURUM.TESLIMAT_BEKLENIYOR}`}
          >
            Siparişlerin Tümünü Görüntüle
          </Table.Link>
        </Flex>
        <Table.Wrapper>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.DataHead></Table.DataHead>
                <Table.DataHead>Müşteri</Table.DataHead>
                <Table.DataHead>Teslimat Adresi</Table.DataHead>
                <Table.DataHead>Bayi / Araç</Table.DataHead>
                <Table.DataHead>Tutar / Adet</Table.DataHead>
                <Table.DataHead>Sipariş Tarihi</Table.DataHead>
                <Table.DataHead>Teslimat Türü</Table.DataHead>
              </Table.Row>
            </Table.Head>
            <Table.Body ref={tableBodyRef} maxHeight={maxHeight}>
              {data.hits && <DashboardTableContainer data={data.hits} />}
            </Table.Body>
          </Table>
        </Table.Wrapper>
      </Content.Wrapper>
    </Content>
  );
}

export default Home;
