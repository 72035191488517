import styled from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';
import { colors, fonts, fontSizes } from '../../../styles/variables';

export const Container = styled.div`
  color: ${colors.grayText};
  font-family: ${fonts.poppins};
  font-size: ${fontSizes.md};
`;

export const Wrapper = styled.div`
padding: 10px 30px;
`

export const LinkContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const Button = styled.button`
  align-items: center;
  color: inherit;
  display: flex;
  font: inherit;
  ${({ noMargin }) => !noMargin && 'margin-right: 20px'};
`;

export const PageLink = styled(ReactRouterLink)`
  align-items: center;
  background-color: ${colors.gray2};
  border-radius: 50%;
  color: inherit;
  display: flex;
  height: 43px;
  justify-content: center;
  margin-right: 5px;
  width: 43px;

  &:hover {
  }
`;

export const Text = styled.p`
  color: ${colors.grayText};
  font-size: ${fontSizes.md};
  font-weight: 500;
`;

export const IconContainer = styled.div`
  font-size: 1.25rem;
  transform: ${({ direction }) =>
    direction === 'left'
      ? 'rotate(90deg)'
      : direction === 'right'
      ? 'rotate(-90deg)'
      : 'unset'};
`;

