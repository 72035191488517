import { useState } from 'react';
import { useParams } from 'react-router';
import { GET, POST } from '../../constants/endpoints';
import { SIPARIS_DURUM } from '../../constants/siparis-durum';
import usePostData from '../usePostData';

const defaultWarn = {
  teslimatDurumu: '',
  teslimEdilememeDurumu: '',
};

const defaultTeslimatDurumu = {
  siparis_id: null,
  teslimat_durum_id: null,
  teslim_edilememe_durum_id: 0,
};

function useTeslimatDurumModalLogic(getData,closeModal) {
  const { siparis_id = null } = useParams();
  const { resource, postData } = usePostData(POST.TESLIMAT_DURUMU);
  const [warn, setWarn] = useState(defaultWarn);
  const [teslimatDurumu, setTeslimatDurumu] = useState({
    ...defaultTeslimatDurumu,
    siparis_id,
  });
  const resetWarn = () => setWarn(defaultWarn);
  const inputlariKontrolEt = () => {
    const warnObj = { ...defaultWarn };
    const { teslimat_durum_id, teslim_edilememe_durum_id } = teslimatDurumu;
    if (!teslimat_durum_id) {
      warnObj.teslimatDurumu = 'Lütfen teslimat durumunu seçiniz.';
    }
    if (
      teslimat_durum_id === SIPARIS_DURUM.TESLIM_EDILEMEDI &&
      !teslim_edilememe_durum_id
    ) {
      warnObj.teslimEdilememeDurumu =
        'Lütfen teslim edilememe nedenini seçiniz.';
    }
    if (warnObj.teslimatDurumu || warnObj.teslimEdilememeDurumu) {
      setWarn(warnObj);
      return false;
    }
    return true;
  };
  const handleVazgecClick = () => {
    closeModal();
  };

  const handleRadioChange = (e) => {
    resetWarn();
    const durum = +e.target.value;
    if (durum === SIPARIS_DURUM.TESLIM_EDILDI) {
      return setTeslimatDurumu({
        ...teslimatDurumu,
        teslimat_durum_id: durum,
        teslim_edilememe_durum_id: 0,
      });
    }
    setTeslimatDurumu({ ...teslimatDurumu, teslimat_durum_id: durum });
  };

  const handleTeslimEdilememeChange = (e) => {
    resetWarn();
    const durum = +e.target.value;
    setTeslimatDurumu({ ...teslimatDurumu, teslim_edilememe_durum_id: durum });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updateData = () => {
      getData(`${GET.SIPARIS_DETAY_DATA}?siparis_id=${siparis_id}`);
    };
    if (inputlariKontrolEt()) {
      postData(POST.TESLIMAT_DURUMU, { ...teslimatDurumu }, updateData);
      closeModal();
      return;
    }
  };

  return {
    handleVazgecClick,
    handleRadioChange,
    handleTeslimEdilememeChange,
    handleSubmit,
    teslimatDurumu,
    warn,
    resource
  };
}

export default useTeslimatDurumModalLogic;
