import React from 'react';
import { Address, Flex } from '../components';
import 'styled-components/macro';
import * as ROUTES from '../constants/routes';

function AddressContainer(props) {
  const { musteri_id, musteri_ad_soyad, cep_telefonu, adres,adres_adi } = props.musteri;
  return (
    <Address>
      <Address.Title>Teslimat Adresi</Address.Title>
      <Flex direction='column' align='flex-start' css='margin-top:20px'>
        <Address.TitleSmall>{adres_adi}</Address.TitleSmall>
        <Address.Text>{adres[1]}</Address.Text>
        <Address.Text>{adres[0]}</Address.Text>
        <Address.Link to={`${ROUTES.MUSTERI_PROFIL}/${musteri_id}`} css="margin-top:10px;">
          <Address.Text>
            <Address.Bold>
              {musteri_ad_soyad} - {cep_telefonu}
            </Address.Bold>
          </Address.Text>
        </Address.Link>
      </Flex>
      {props.siparis_notu && (
        <Flex direction='column' align='flex-start' css='margin-top:30px;'>
          <Address.Title css='margin-bottom:10px;'>Sipariş Notu</Address.Title>
          <Address.Text>{props.siparis_notu}</Address.Text>
        </Flex>
      )}
    </Address>
  );
}

export default AddressContainer;
