import { colors } from '../variables';
const durumaGoreColorGetir = (id, bg = false, lightIcon = false) => {
  switch (id) {
    case 3:
      return bg ? colors.gray : colors.grayText;
    case 7:
    case 10:
    case 11:
      return !bg || lightIcon ? colors.red : colors.redLight;
    case 6:
      return !bg || lightIcon ? colors.green : colors.greenLight;
    case 'siparisAlindi':
      return !bg || lightIcon ? colors.blue : colors.blueLight;
    default:
      break;
  }
};

export default durumaGoreColorGetir;
