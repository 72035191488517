import React, { useEffect } from 'react';
import 'styled-components/macro';
import { Backdrop, Dialog, Flex } from '../components';
import { Portal } from '../helpers';
import { useDialogLogic } from '../hooks';

const DialogContainer = (props, btnRef) => {
  const { dialog, resetDialog } = useDialogLogic();
  const { text, title, icon, variant, handleOnayClick, noBtn, yesBtn } = props;

  /*   useEffect(() => {
    if (dialog.active) {
      const vazgecBtn = btnRef?.current;
      vazgecBtn.focus();
    }
  }, [dialog.active, btnRef]); */

  const handleVazgecClick = (e) => {
    e.stopPropagation();
    resetDialog();
  };

  // !!! Change 150px to a variable.
  return (
    <Portal>
      <Backdrop />
      <Dialog
        variant={variant}
        css={`
          left: calc(50% + 150px);
        `}
      >
        <Flex width='auto'>
          <Dialog.IconContainer>{icon}</Dialog.IconContainer>
        </Flex>
        <Flex direction='column' css='margin-top:40px;'>
          <Dialog.Title>{title}</Dialog.Title>
          <Dialog.Text>{text}</Dialog.Text>
        </Flex>
        <Dialog.Box>
          {noBtn || (
            <Dialog.Button
              btnRef={btnRef}
              onClick={handleVazgecClick}
              variant='gray'
              autoFocus
            >
              Vazgeç
            </Dialog.Button>
          )}
          {yesBtn ? (
            yesBtn
          ) : yesBtn !== false ? (
            <Dialog.Button onClick={handleOnayClick}>Evet</Dialog.Button>
          ) : null}
        </Dialog.Box>
      </Dialog>
    </Portal>
  );
};

export default DialogContainer;
