import styled from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  colors,
  fonts,
  fontSizes,
  radius,
  screens,
} from '../../../styles/variables';
import { durumaGoreColorGetir } from '../../../styles/helpers';
import { TESLIMAT_TUR } from '../../../constants/teslimat-tur';

export const Container = styled.table`
  border-collapse: collapse;
  font-family: ${fonts.roboto};
  font-size: ${fontSizes.md};
  table-layout: fixed;
  width: 100%;
`;
export const Wrapper = styled.div`
  background-color: white;
  border-radius: ${radius.roundedMD};
  /*   max-height: ${({ maxHeight }) => maxHeight || '54vh'}; */
  margin-top: 15px;
  padding: 20px;
  overflow: hidden;

  @media ${screens.xl} {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Head = styled.thead`
  background-color: ${colors.gray};
  border-radius: ${radius.roundedSM};

  & tr:last-child {
    padding-right: 18px; // scrollbar width
  }

  /* Ana sayfa table style */

  & tr th:nth-child(1) {
    width: 5%;
  }
  & tr th:nth-child(2) {
    width: 18%;
  }
  & tr th:nth-child(3) {
    width: 17%;
  }
  & tr th:nth-child(4) {
    width: 15%;
  }
  & tr th:nth-child(5) {
    width: 12%;
  }
  & tr th:nth-child(6) {
    width: 15%;
  }
  & tr th:nth-child(7) {
    width: 18%;
  }

  & > tr th:nth-child(1) {
    border-bottom-left-radius: ${radius.roundedSM};
    border-top-left-radius: ${radius.roundedSM};
  }

  & > tr th:last-child {
    border-bottom-right-radius: ${radius.roundedSM};
    border-top-right-radius: ${radius.roundedSM};
  }
`;

export const Body = styled.tbody`
  max-height: ${({ maxHeight }) => maxHeight};

  /* Ana sayfa table style */
  & tr td:nth-child(1) {
    width: 5%;
  }
  & tr td:nth-child(2) {
    width: 18%;
  }
  & tr td:nth-child(3) {
    width: 17%;
  }
  & tr td:nth-child(4) {
    width: 15%;
  }
  & tr td:nth-child(5) {
    width: 12%;
  }
  & tr td:nth-child(6) {
    width: 15%;
  }
  & tr td:nth-child(7) {
    width: 18%;
  }

  & > tr td:nth-child(1) {
    border-bottom-left-radius: ${radius.roundedSM};
    border-top-left-radius: ${radius.roundedSM};
  }

  & > tr td:last-child {
    border-bottom-right-radius: ${radius.roundedSM};
    border-top-right-radius: ${radius.roundedSM};
  }
`;

export const DataHead = styled.th`
  color: ${colors.grayText};
  font-family: ${fonts.poppins};
  flex-shrink: 0;
  font-weight: 500;
  text-align: left;
  padding: 0.8em 1.2em;
  ${({ width }) => `width: ${width} !important`};

  @media ${screens.lg} {
    font-size: ${fontSizes.sm};
    padding: 0.8em;
  }
`;

export const Data = styled.td`
  flex-shrink: 0;
  padding: 0.8em 1.2em;
  vertical-align: ${({ verticalAlign }) => verticalAlign || 'baseline'};
  ${({ width }) => `width: ${width} !important;`}
  @media ${screens.lg} {
    padding: 0.8em;
  }
`;

export const Row = styled.tr`
  border-radius: ${radius.roundedSM};
  display: flex;
  transition: background-color 150ms;
  width: 100%;
`;

export const RowLink = styled(ReactRouterLink)`
  border-radius: ${radius.roundedSM};
  color: inherit;
  display: flex;
  transition: background-color 150ms;
  outline:none;
  width: 100%;

  &:hover,
  &:focus {
    background-color: ${colors.gray};
  }
`;

export const Text = styled.p`
  color: ${colors.darkBlue};
`;

export const TextSmall = styled.p`
  font-size: ${fontSizes.sm};
`;

export const Price = styled.p`
  color: ${colors.green};
`;

export const Order = styled.p`
  color: ${({ kind }) => {
    switch (kind) {
      case TESLIMAT_TUR.ILERI_TARIH:
        return colors.orange;
      case TESLIMAT_TUR.HEMEN:
        return colors.blue;
      default:
        break;
    }
  }};
`;

export const OrderSituation = styled.div`
  align-items: center;
  background-color: ${({ durum }) => durumaGoreColorGetir(durum, true)};
  border-radius: 30px;
  color: ${({ durum }) => durumaGoreColorGetir(durum)};
  display: flex;
  padding: 12px;
  width: fit-content;
`;

export const OrderSituationIconContainer = styled.div`
  font-size: 1.3rem;
  display: flex;
  margin-right: 9px;
`;

export const Pill = styled.div`
  align-items: center;
  background-color: ${colors.greenLight};
  border-radius: 30px;
  color: ${colors.green};
  display: flex;
  padding: 10px 30px;
  text-align: center;
  width: fit-content;
`;

export const IconContainer = styled.div`
  align-items: center;
  background-color: ${({ variant }) =>
    variant === 'purple' ? colors.purpleLight : colors.blueLight};
  border-radius: 50%;
  color: ${({ variant }) =>
    variant === 'purple' ? colors.purple : colors.blue};
  display: flex;
  font-size: 1.3rem;
  height: 43px;
  justify-content: center;
  width: 43px;

  /* @media ${screens.xl} {
    display: none;
  } */
`;

export const Link = styled(ReactRouterLink)`
  color: ${colors.grayText};
  font-family: ${fonts.poppins};

  &:hover {
    text-decoration: underline;
  }
`;
