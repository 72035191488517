import styled from 'styled-components/macro';
import {
  colors,
  fonts,
  fontSizes,
  radius,
  screens,
} from '../../../styles/variables';
import { commonFontSet } from '../../../styles/mixins';
import { durumaGoreColorGetir } from '../../../styles/helpers';

export const Container = styled.div`
  background-color: ${({ durum_id }) => durumaGoreColorGetir(durum_id, true)};
  border-radius: ${radius.roundedMD};
  display: flex;
  height: 100%;
  margin-right: ${({ noMargin }) => (noMargin ? 0 : '20px')};
  padding: 20px;

  @media ${screens.lg2} {
    margin-right: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const ContentWrapper = styled.div`
  flex-basis: 100%;
`;

export const Text = styled.p`
  ${commonFontSet}
  color:${({ durum_id }) => durumaGoreColorGetir(durum_id)};
`;

export const TextSmall = styled.p`
  ${commonFontSet}
  color: ${colors.darkBlue};
  font-size: ${fontSizes.sm};
`;

export const Date = styled.p`
  color: ${colors.darkPurple};
  font-family: ${fonts.roboto};
`;

export const IconContainer = styled.div`
  background-color: ${({ durum_id }) =>
    durumaGoreColorGetir(durum_id, false, true)};
  border-radius: ${radius.roundedMD};
  color: white;
  display: flex;
  font-size: 2rem;
  padding: 19px;
`;

export const Notification = styled.p`
  color: ${colors.red};
  font-size: 0.813rem;
  margin-top: 10px;
  line-height: 0.1;
`;
