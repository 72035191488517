import styled from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';
import { colors, fonts, fontSizes, radius, screens } from '../../../styles/variables';

export const Container = styled.div`
  margin-top: 30px;
`;

export const Card = styled.div`
  background-color: white;
  border-radius: ${radius.roundedMD};
  color: ${colors.grayText};
  display: inline-block; // !!! Remove this style.
  flex-basis: 100%;
  font-family: ${fonts.poppins};
  font-weight: 500;
  margin-right: 30px;
  padding: 20px;

  @media ${screens.lg} {
    margin-right: 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;

  & ${Card}:last-child {
    margin-right: 0;
  }
`;

export const TopWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const IconContainer = styled.div`
  background-color: ${colors.blueLight};
  border-radius: ${radius.roundedMD};
  color: ${colors.blue};
  font-size: 3.2rem;
  line-height: 0.5;
  margin-right: 20px;
  padding: 20px;

  @media ${screens.xl} {
    font-size: 2.5rem;
    padding: 15px;
  }
`;

export const TrendingIconContainer = styled.div``;

export const Searchbox = styled.input`
  background-color: ${colors.gray};
  border-radius: 7px;
  font-family: inherit;
  font-size: ${fontSizes.sm};
  margin-top: 20px;
  padding: 16px 20px;
  width: 100%;
`;

export const StatText = styled.p`
  font-size: ${fontSizes.md};
  margin-right: 5px;
  white-space: pre;
`;

export const StatContainer = styled.div`
  align-items: center;
  background-color: ${({ up }) => (up ? colors.greenLight : colors.redLight)};
  border-radius: 19px;
  color: ${({ up }) => (up ? colors.green : colors.red)};
  display: flex;
  margin-left: auto;
  padding: 8px 23px;

  & ${TrendingIconContainer} {
    ${({ up }) => !up && 'transform:rotateX(180deg)'};
  }

  @media ${screens.xl} {
    padding: 5px 10px;
  }
`;

export const Text = styled.p``;

export const Title = styled.h3`
  font-size: ${fontSizes.md};
  font-weight: inherit;
  margin-right: 0.5em;
`;

export const Count = styled.p`
  color: ${colors.darkBlue};
  font-size: ${fontSizes.xl};
`;

export const ButtonLink = styled(ReactRouterLink)`
  align-items: center;
  color: inherit;
  display: flex;
  flex-shrink: 0;
  font-size: ${fontSizes.sm};
  margin-top: 5px;

  &:hover {
    text-decoration: underline;
  }
`;
