import React, { useState } from 'react';
import { ModalContext } from '../context';

function ModalProvider({ children, ...rest }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <ModalContext.Provider value={{ showModal, setShowModal }} {...rest}>
      {children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
